import React, {useEffect} from 'react';
import Button_lilac from "../../shared/UI/Button_lilac/Button_lilac";
import {UserApi} from "../../shared/api/userApi";
import useCollectErrors from "../../shared/utils/hooks/useCollectErrors";

const LoginButton = ({setErrors, email, password, children,}) => {
    const login = UserApi.useLogin();
    const [loginWithCollectedErrors, errors] = useCollectErrors(async () => (await login)(email, password));

    useEffect(() => {
        setErrors(errors)
    }, [errors])

    const handleLoginSubmit = async (e) => {
        e.preventDefault();
        await loginWithCollectedErrors()
    }

    return (
        <button onClick={(e) => handleLoginSubmit(e)} type="submit">{children}</button>
    );
};

export default LoginButton;