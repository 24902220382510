import React from 'react';
import Hero from "./models/Hero/Hero";
import Benefits from "./models/Benefits/Benefits";
import AboutCodeFeatures from "./models/AboutCodeFeatures/AboutCodeFeatures";
import OfferContent from "./models/OfferContent/OfferContent";
import AboutCompany from "./models/AboutCompany/AboutCompany";

const Home = () => {
    return (
        <div>
            <Hero/>
            <AboutCompany/>
            <Benefits/>
            <AboutCodeFeatures/>
            <OfferContent/>
        </div>
    );
};

export default Home;