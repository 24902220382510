import React from 'react';
import cl from "./FeedbackIsSentPopup.module.css";
import WaitForReplyIcon from "../../../shared/UI/icons/WaitForReplyIcon";

const FeedbackIsSentPopup = ({setPopupIsVisible}) => {
    return (
        <div className={cl.wrap} onClick={()=>setPopupIsVisible(false)}>
            <div className={cl.popupWrap} onClick={(e)=>e.stopPropagation()}>
                <button className={cl.exitButton} onClick={()=>setPopupIsVisible(false)}>x</button>
                <div className={cl.titleWrap}>
                    <h2 className={cl.title}>Email is received </h2>
                    <h6 className={cl.text}>We will answer you soon. It usually takes less than one business day  </h6>
                </div>
                <span className={cl.waitIcon}><WaitForReplyIcon/></span>
            </div>
        </div>
    );
};

export default FeedbackIsSentPopup;