import React, {useState} from 'react';
import cl from './TodoList.module.css'
import Button_lilac from "../../../../../shared/UI/Button_lilac/Button_lilac";

const TodoList = () => {
    const [tasks, setTasks] = useState([]);
    const [taskInput, setTaskInput] = useState('');

    const handleInputChange = (event) => {
        setTaskInput(event.target.value);
    };

    const addTask = () => {
        if (taskInput.trim() === '') {
            return;
        }

        setTasks([...tasks, taskInput]);
        setTaskInput('');
    };

    const deleteTask = (index) => {
        const updatedTasks = tasks.filter((_, i) => i !== index);
        setTasks(updatedTasks);
    };

    return (
        <div className={cl.wrap}>
            <header></header>
            <div>
                <h3 className={cl.title}>Todo List</h3>
                <div className={cl.newTaskContainer}>
                    <input
                        type="text"
                        value={taskInput}
                        onChange={handleInputChange}
                        placeholder="Enter a new task"
                    />
                    <Button_lilac onClick={addTask}>Add</Button_lilac>
                </div>

                <ul className={cl.list}>
                    {tasks.map((task, index) => (
                        <li key={index}>
                            <span className={cl.task}>{task}</span>
                            <button onClick={() => deleteTask(index)}>Delete</button>
                        </li>
                    ))}
                </ul>
            </div>

        </div>
    );
};

export default TodoList;