import React from 'react';
import cl from "./CancelButton.module.css";

const CancelButton = ({ children, ...props}) => {
    return (
        <button {...props} className={cl.button}>
            {children}
        </button>
    );
};

export default CancelButton;