import React, {useEffect} from 'react';
import {setIsAuth, setUserSessionData} from "../../shared/store/userSessionDataReducer";
import useCollectErrors from "../../shared/utils/hooks/useCollectErrors";
import {UserApi} from "../../shared/api/userApi";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import cl from './ChangePassword.module.css';

const ChangePasswordButton = ({setShowSaved, oldPassword, newPassword, setErrors, children, className}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [changePasswordCollected, errors] = useCollectErrors(() => UserApi.changePassword(oldPassword, newPassword));

    useEffect(() => {
        setErrors(errors)
    }, [errors])

    const handleChangePassword = async (e) => {
        try {
            const response = await changePasswordCollected(oldPassword, newPassword)
            if (response) {
                setShowSaved(true)
                setTimeout(() => {
                    setShowSaved(false)
                    navigate("/login")
                    dispatch(setIsAuth(false))
                    dispatch(setUserSessionData({}))
                }, 1500)
            }
        } catch (e) {
            console.log(e)
        }
    }
    return (
        <button onClick={handleChangePassword} className={className}>
            {children}
        </button>
    );
};

export default ChangePasswordButton;