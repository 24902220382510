import axios from "axios";

const $host = axios.create({
    withCredentials:true,
    baseURL: process.env.REACT_APP_API_URL,
    headers:{
        Accept: 'application/json'
    }

})

const $authHost = axios.create({
    withCredentials:true,
    baseURL: process.env.REACT_APP_API_URL
})

const authInterceptor = config => {
    config.headers["Authorization"] = `Bearer ${localStorage.getItem('accessToken')}`
    return config
}

$authHost.interceptors.request.use(authInterceptor)
$authHost.interceptors.response.use((config) => {
    return config;
},async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && error.config && !error.config._isRetry) {
        originalRequest._isRetry = true;
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}updateTokens`, {withCredentials: true})
            localStorage.setItem('accessToken', response.data);
            return $authHost.request(originalRequest);
        } catch (e) {
            console.log('Unauthorized')
        }
    }
    throw error;
})
export {
    $host,
    $authHost
}