import cl from './Hint.module.css';

const Hint = ({children, name,isHovered, style}) => {

    return (
        <span style={style} className={cl.hintWrap + (isHovered ? ` ${cl.hintWrapEnterActive}` : '')}>
            {name&&<span><span style={{color:'var(--lilac)', fontWeight:'var(--semiBold)'}}>{name}</span> - </span> }
            <span>{children}</span>
        </span>
    );
};

export default Hint;