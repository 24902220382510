import React, {useEffect} from 'react';
import MenuItem from "../../shared/UI/MenuItem/MenuItem";
import {useSelector} from "react-redux";

const ProjectMenu = ({projectList, styles={}}) => {
    const isAuth = useSelector(state => state.isAuth.isAuth)
    const user = useSelector(state => state.userSessionData.userSessionData.userDetails)
    const userIsPaid = (isAuth && user.subscriptionStatus === 'Paid');

    return (
        <ul>
            {projectList.map((project) => {
                    let menuItemStyle = styles.availableProjectMenuItem
                    let linkForMenuItem = '/pet-projects/' + project.id

                    if (project.accessLevel === 'paid' && !isAuth) {
                        linkForMenuItem = '/login'
                        menuItemStyle = styles.restrictedProjectMenuItem
                    }
                    if (project.accessLevel === 'paid' && !userIsPaid && isAuth) {
                        linkForMenuItem = '/offer'
                        menuItemStyle = styles.restrictedProjectMenuItem
                    }

                    return (
                        <li key={project.id}>
                            <MenuItem
                                foldingLevel={1}
                                key={project.id}
                                id={project.id}
                                linkForMenuItem={linkForMenuItem}
                                styleClass={menuItemStyle}
                                activeStyleClass={styles.activeProjectMenuItem}
                            >{project.title}</MenuItem>
                        </li>)
                }
            )}
        </ul>);
};

export default ProjectMenu;