import React, {useEffect, useMemo, useState} from 'react';
import cl from './Code.module.css'
import Command from "./Command/Command";
import {CommandApi} from "../../shared/api/commandApi";

const Code = ({rawCode = '', styleClasses = ''}) => {
    let types;
    const [finalCode, setFinalCode] = useState([]);

    useEffect(() => {
        setTypes()

        async function setTypes() {
            try {
                types = await CommandApi.getAllCommandTypes()
                setFinalCode(highlightCode(rawCode));
            } catch (e) {
                setFinalCode ('Network error')
            }

        }
    }, []);

    function createCommandComponent(commandName, type, nodeStart) {
        return <Command commandName={commandName} typeData={type} key={nodeStart}/>;
    }

    function highlightCode(rawCode) {
        let acornLoose = require("acorn-loose");
        let index = 0;
        const abstractSyntaxTree = acornLoose.parse(rawCode, {ecmaVersion: 2020});
        const highlightedCommands = [];

        iterateNode(abstractSyntaxTree.body, true, [], [], [])

        function iterateNode(node, newScope = false, outerVariables = [], functions = [], params = []) {
            //Change node to array in any case
            const nodeArray = Array.isArray(node) ? node : [node];

            const localVariables = [];
            if (newScope) {
                functions.push(...collectFunctions(node))
            }

            function collectFunctions(node) {
                const nodeArray = Array.isArray(node) ? node : [node];
                const functions = [];
                for (const node of nodeArray) {
                    if (node.type === 'FunctionDeclaration') {
                        functions.push(node.id.name)
                    }
                }
                return functions
            }

            for (const node of nodeArray) {

                if (node.start > index) {
                    highlightBetweenNodes(rawCode.substr(index, node.start - index), index)
                    index = node.start;
                }

                function highlightBetweenNodes(code) {
                    for (let i = 0; i < code.length; i++) {
                        let char = code[i], prev = code[i - 1], next = code[i + 1];
                        //if one line comment starts find the end and highlight it all
                        if (char === '/' && next === '/') {
                            const lastCharIndex = code.indexOf('\n', i + 1)
                            highlightedCommands.push(createCommandComponent(code.substr(i, lastCharIndex + 1 - i), types.comment));
                            i = lastCharIndex;
                            continue
                        }

                        //if multiple lines comment starts find the end and highlight it all
                        if (char === '/' && next === '*') {
                            const lastCharIndex = code.indexOf('*/', i + 1)
                            highlightedCommands.push(createCommandComponent(code.substr(i, lastCharIndex + 2 - i), types.comment));
                            i = lastCharIndex + 1;
                            continue
                        }
                        highlightedCommands.push(code[i])
                    }
                }

                switch (node.type) {
                    case 'Literal':
                        handleLiteral(node);
                        break;
                    case 'Identifier':
                        handleIdentifier(node, localVariables, outerVariables, functions, params);
                        break;
                    case 'VariableDeclaration':
                        handleVariableDeclaration(node, localVariables, outerVariables, functions, params);
                        break;
                    case 'VariableDeclarator':
                        handleVariableDeclarator(node, localVariables, outerVariables, functions, params);
                        break;
                    case 'CallExpression':
                        handleCallExpression(node, localVariables, outerVariables, functions, params);
                        break;
                    case 'MemberExpression':
                        handleMemberExpression(node, localVariables, outerVariables, functions, params);
                        break;
                    case 'BlockStatement':
                        handleBlockStatement(node, localVariables, outerVariables, functions, params);
                        break;
                    case 'ExpressionStatement':
                        handleExpressionStatement(node, localVariables, outerVariables, functions, params);
                        break;
                    case 'ArrowFunctionExpression':
                        handleArrowFunctionExpression(node, localVariables, outerVariables, functions, params);
                        break;
                    case 'FunctionExpression':
                        handleFunctionExpression(node, localVariables, outerVariables, functions, params);
                        break;
                    case 'IfStatement':
                        handleIfStatement(node, localVariables, outerVariables, functions, params);
                        break;
                    case 'BinaryExpression':
                        handleBinaryExpression(node, localVariables, outerVariables, functions, params);
                        break;
                    case 'LogicalExpression':
                        handleLogicalExpression(node, localVariables, outerVariables, functions, params);
                        break;
                    case 'AssignmentExpression':
                        handleAssignmentExpression(node, localVariables, outerVariables, functions, params);
                        break;
                    case 'FunctionDeclaration':
                        handleFunctionDeclaration(node, localVariables, outerVariables, functions, params);
                        break;
                    case 'ReturnStatement':
                        handleReturnStatement(node, localVariables, outerVariables, functions, params);
                        break;
                    case 'TemplateLiteral':
                        handleTemplateLiteral(node, localVariables, outerVariables, functions, params);
                        break;
                    case 'UpdateExpression':
                        handleUpdateExpression(node, localVariables, outerVariables, functions, params);
                        break;
                    case 'ArrayExpression':
                        handleArrayExpression(node, localVariables, outerVariables, functions, params);
                        break;
                    case 'ObjectExpression':
                        handleObjectExpression(node, localVariables, outerVariables, functions, params);
                        break;
                    case 'ConditionalExpression':
                        handleConditionalExpression(node, localVariables, outerVariables, functions, params);
                        break;
                    case 'Property':
                        handleProperty(node, localVariables, outerVariables, functions, params);
                        break;
                    case 'NewExpression':
                        handleNewExpression(node, localVariables, outerVariables, functions, params);
                        break;
                    default:
                        // Handle unknown node type
                        break;
                }

                function handleLiteral(node) {
                    let literalType = typeof node.value;
                    if (node.value === null) {
                        literalType = 'null'
                    }
                    highlightedCommands.push(createCommandComponent(node.raw, types[literalType], index));
                    index += node.raw.length;
                }

                function handleIdentifier(node, localVariables, outerVariables, functions, params) {
                    let identifierType;
                    if (localVariables.includes(node.name) || outerVariables.includes(node.name)) {
                        identifierType = types.variable
                    } else {
                        if (functions.includes(node.name)) {
                            identifierType = types.func
                        } else {
                            if (params.includes(node.name)) {
                                identifierType = types.params
                            } else
                                identifierType = types.specific
                        }
                    }
                    highlightedCommands.push(createCommandComponent(node.name, identifierType, index));
                    index += node.name.length;
                }

                function handleVariableDeclaration(node, localVariables, outerVariables, functions, params) {
                    highlightedCommands.push(createCommandComponent(node.kind, types.specific, index));

                    localVariables.push(node.declarations[0].id.name)

                    index += node.kind.length;
                    iterateNode(node.declarations, false, [...outerVariables, ...localVariables], functions, params)
                }

                function handleVariableDeclarator(node, localVariables, outerVariables, functions, params) {
                    highlightedCommands.push(createCommandComponent(node.id.name, types.variable, index));
                    index += node.id.name.length;
                    if (node.init) {
                        iterateNode(node.init, false, [...outerVariables, ...localVariables], functions, params)
                    }
                }

                function handleCallExpression(node, localVariables, outerVariables, functions, params) {
                    iterateNode(node.callee, false, [...outerVariables, ...localVariables], functions, params)
                    iterateNode(node.arguments, false, [...outerVariables, ...localVariables], functions, params)
                }

                function handleMemberExpression(node, localVariables, outerVariables, functions, params) {
                    iterateNode(node.object, false, [...outerVariables, ...localVariables], functions, params)
                    iterateNode(node.property, false, [...outerVariables, ...localVariables], functions, params)
                }

                function handleBlockStatement(node, localVariables, outerVariables, functions, params) {
                    iterateNode(node.body, true, [...outerVariables, ...localVariables], functions, params)

                }

                function handleExpressionStatement(node, localVariables, outerVariables, functions, params) {
                    iterateNode(node.expression, false, [...outerVariables, ...localVariables], functions, params)

                }

                function handleArrowFunctionExpression(node, localVariables, outerVariables, functions, params) {
                    //handle params
                    const paramsNodeArray = node.params;
                    paramsNodeArray.forEach((node) => {
                        params.push(node.name)
                    })
                    iterateNode(node.body, false, [...outerVariables, ...localVariables], functions, params)

                }

                function handleFunctionExpression(node, localVariables, outerVariables, functions, params) {
                    //handle params
                    const paramsNodeArray = node.params;
                    paramsNodeArray.forEach((node) => {
                        params.push(node.name)
                    })
                    highlightedCommands.push(createCommandComponent('function', types.specific, index));
                    index += 8;
                    iterateNode(node.body, false, [...outerVariables, ...localVariables], functions, params)
                }

                function handleIfStatement(node, localVariables, outerVariables, functions, params) {
                    highlightedCommands.push(createCommandComponent('if', types.specific, index));
                    index += 2;
                    iterateNode(node.test, false, [...outerVariables, ...localVariables], functions, params)
                    iterateNode(node.consequent, false, [...outerVariables, ...localVariables], functions, params)
                    if (node?.alternate) {
                        highlightedCommands.push(<span>{rawCode.substr(index, node.consequent.end - index + 1)}</span>)
                        highlightedCommands.push(createCommandComponent('else', types.specific, index));

                        index = node.consequent.end + 5;
                        iterateNode(node.alternate, false, [...outerVariables, ...localVariables], functions, params)
                    }
                }

                function handleBinaryExpression(node, localVariables, outerVariables, functions, params) {
                    iterateNode(node.left, false, [...outerVariables, ...localVariables], functions, params)
                    iterateNode(node.right, false, [...outerVariables, ...localVariables], functions, params)
                }

                function handleLogicalExpression(node, localVariables, outerVariables, functions, params) {
                    iterateNode(node.left, false, [...outerVariables, ...localVariables], functions, params)
                    iterateNode(node.right, false, [...outerVariables, ...localVariables], functions, params)
                }

                function handleAssignmentExpression(node, localVariables, outerVariables, functions, params) {
                    iterateNode(node.left, false, [...outerVariables, ...localVariables], functions, params)
                    iterateNode(node.right, false, [...outerVariables, ...localVariables], functions, params)
                }

                function handleFunctionDeclaration(node, localVariables, outerVariables, functions, params) {
                    const paramsNodeArray = node.params;
                    paramsNodeArray.forEach((node) => {
                        params.push(node.name)
                    })
                    highlightedCommands.push(createCommandComponent('function', types.specific, index));
                    index += 8;
                    iterateNode(node.id, false, [...outerVariables, ...localVariables], functions, params)
                    iterateNode(node.body, false, [...outerVariables, ...localVariables], functions, params)
                }

                function handleReturnStatement(node, localVariables, outerVariables, functions, params) {
                    highlightedCommands.push(createCommandComponent('return', types.specific, index));
                    index += 6;
                    if (node.argument)
                        iterateNode(node.argument, false, [...outerVariables, ...localVariables], functions, params)
                }

                function handleTemplateLiteral(node) {
                    const stringValue = rawCode.substr(node.start, node.end - node.start);
                    highlightedCommands.push(createCommandComponent(stringValue, types.templateLiteral, index));
                    index += stringValue.length;
                }

                function handleUpdateExpression(node, localVariables, outerVariables, functions, params) {
                    iterateNode(node.argument, false, [...outerVariables, ...localVariables], functions, params)

                }

                function handleArrayExpression(node) {
                    const stringValue = rawCode.substr(node.start, node.end - node.start);
                    highlightedCommands.push(createCommandComponent(stringValue, types.array, index));

                    index += stringValue.length;
                }

                function handleObjectExpression(node, localVariables, outerVariables, functions, params) {
                    // const stringValue = code.substr(node.start, node.end - node.start);
                    // elements.push(getCommandPushTemplate(stringValue, types.object));
                    // index += stringValue.length;
                    iterateNode(node.properties, false, [...outerVariables, ...localVariables], functions, params)
                }

                function handleConditionalExpression(node, localVariables, outerVariables, functions, params) {
                    const stringValue = rawCode.substr(node.test.start, node.test.end - node.test.start);
                    index += node.test.end - node.test.start;
                    highlightedCommands.push(createCommandComponent(stringValue, types.conditionalExpressionTest, index));
                    iterateNode(node.consequent, false, [...outerVariables, ...localVariables], functions, params)
                    iterateNode(node.alternate, false, [...outerVariables, ...localVariables], functions, params)
                }

                function handleProperty(node) {
                    const stringValue = rawCode.substr(node.start, node.end - node.start);
                    index += node.end - node.start;
                    highlightedCommands.push(createCommandComponent(stringValue, types.property, index));
                }

                function handleNewExpression(node) {
                    const stringValue = rawCode.substr(node.start, node.end - node.start);
                    index += node.end - node.start;
                    highlightedCommands.push(createCommandComponent(stringValue, types.newExpression, index));
                }
            }
        }

        highlightedCommands.push(<span>{rawCode.substr(index, rawCode.length)}</span>)
        return highlightedCommands;
    }

    return (
        <div style={{position: 'relative'}} id="jsCodeFrame">
            <div className={cl.codeDefault + ' ' + styleClasses} id='jsCodeWrap'>
                {finalCode}
            </div>
        </div>

    );

}
export default Code;

