import React from 'react';
import {Link, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {UserApi} from "../../shared/api/userApi";
import {setIsAuth, setUserSessionData} from "../../shared/store/userSessionDataReducer";

const LogOut = ({children}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const logoutHandle = async () => {
        try {
            const data = await UserApi.logout()
            navigate("/login")
            dispatch(setIsAuth(false))
            dispatch(setUserSessionData({}))
        } catch (e) {
            alert(e.response.data.message)
        }
    }

    return (
        <Link to={'/logout'} onClick={logoutHandle}>
            {children}
        </Link>
    );
};

export default LogOut;