import React from 'react';
import {UserApi} from "../../shared/api/userApi";
import {setIsAuth, setUserSessionData} from "../../shared/store/userSessionDataReducer";
import {useDispatch} from "react-redux";

const RecoverPassword = ({email, setRecoverPopupIsVisible, children}) => {
    const dispatch = useDispatch();

    const handleSendNewPassword = async (e) => {
        try {
            e.preventDefault()
            await UserApi.sendNewPassword(email)
            dispatch(setIsAuth(false))
            dispatch(setUserSessionData({}))
            setRecoverPopupIsVisible(true)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <button onClick={(e) => handleSendNewPassword(e)}>
            {children}
        </button>);
};

export default RecoverPassword;