import React, {useEffect, useState} from 'react';
import cl from './NumberOfOnlinePeople.module.css'
import {UserApi} from "../../shared/api/userApi";

const NumberOfOnlinePeople = () => {
    const [onlinePeople, setOnlinePeople] = useState(0);

    useEffect(() => {
        fetchOnlinePeople();

        async function fetchOnlinePeople() {
            try {
                const response = await UserApi.getOnlineUsers();
                setOnlinePeople(response);
            } catch (error) {
                console.log(error);
            }
        }
    }, []);

    return (
        <p className={cl.wrap}>
            People on this page:<span> ~{onlinePeople}</span>
        </p>
    );
};

export default NumberOfOnlinePeople;