import React from 'react';
import cl from './Button_lilac.module.css';

const Button_lilac = ({ children, className, ...props }) => {
    return (
        <button {...props} className={`${cl.button} ${className}`}>
            {children}
        </button>
    );
};

export default Button_lilac;