import React, {useEffect, useState} from 'react';

import './App.css'

import {BrowserRouter as Router} from 'react-router-dom'
import Header from "../widgets/Header/Header";
import AppRouter from "./router/AppRouter";
import Footer from "../widgets/Footer/Footer";
import {setIsAuth, setUserDetails, setUserSessionData} from "../shared/store/userSessionDataReducer";
import {useDispatch} from "react-redux";
import {UserApi} from "../shared/api/userApi";
import Loader from "../shared/UI/Loader/Loader";
import Skeleton from "./ui/Skeleton/Skeleton";


function App() {
    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch();

    useEffect(() => {
        userInit()

        async function userInit() {
            try {
                const user = await UserApi.getUser();
                dispatch(setIsAuth(true));
                dispatch(setUserDetails(user));
                setLoading(false)
            } catch (e) {
                setLoading(false)
            }
        }
    }, [])

    if (loading) {
        return <Loader/>
    }
    return (
        <Router className="App">
            <Header/>
            <Skeleton>
                <AppRouter/>
            </Skeleton>
            <Footer/>
        </Router>
    );
}

export default App;
