import React from 'react';
import cl from '../Guide.module.css'
import localCl from './JsGuide.module.css'
import TitleWithComment from "../../../shared/UI/TitleWithComment/TitleWithComment";
import TitleNumbered from "../../../shared/UI/TitleNumbered/TitleNumbered";
import OfferSmall from "../../../widgets/OfferSmall/OfferSmall";
import SyntaxHighlighter from "react-syntax-highlighter";
import {vs2015} from "react-syntax-highlighter/dist/cjs/styles/hljs";
import {useSelector} from "react-redux";

const JsGuide = ({}) => {
    const user = useSelector(state => state.userSessionData.userSessionData.userDetails);
    return (
        <div className={cl.wrap + ' ' + localCl.wrap} style={user?.subscriptionStatus !== 'Paid'?{maxHeight: '1447px'}:{}}>
            <div className={cl.courseWrap}>
                <div className={cl.titleWrap}>
                    <TitleWithComment
                        data={{title: 'JavaScript', comment: 'Essential JavaScript details for all projects'}}/>
                </div>
                <div className={cl.paragraphsWrap}>
                    <div className={cl.paragraphWrap}>
                        <div className={cl.courseTitleWrap}>
                            <TitleNumbered title={'What is JavaScript?'} number={'1'}/>
                        </div>
                        <p className={cl.courseTextWrap}>
                            JavaScript, often abbreviated as <b>JS</b>, is a versatile and essential programming
                            language for
                            <b>web development</b>. Unlike HTML, which defines the structure of a
                            webpage, and CSS, which handles its appearance, JavaScript is all
                            about <b>functionality</b> and <b>interactivity</b>
                        </p>
                        <p className={cl.courseTextWrap}>
                            Just as CSS can be embedded within HTML using &lt;style&gt; tags or linked externally,
                            JavaScript
                            offers the same option. But it is better practice to
                            include &lt;script&gt; tag in the end of the &lt;body&gt;
                        </p>
                        <p className={cl.courseTextWrap}>
                            For larger projects or improved code organization, you can opt for an <b>external JavaScript
                            file</b>, connected at the end of the HTML document using a <b>&lt;script&gt;</b> tag:
                        </p>

                        <SyntaxHighlighter wrapLongLines={false} language="htmlbars" style={vs2015}>
                            {'<!DOCTYPE html>\n' +
                            '<html>\n' +
                            '<head>\n' +
                            '    <title>External JavaScript</title>\n' +
                            '</head>\n' +
                            '<body>\n' +
                            '    <!-- HTML content -->\n' +
                            '    <script src="script.js"></script>\n' +
                            '</body>\n' +
                            '</html>'}
                        </SyntaxHighlighter>

                    </div>
                    <div className={cl.paragraphWrap}>
                        <div className={cl.courseTitleWrap}>
                            <TitleNumbered title={'Variables'} number={'2'}/>
                        </div>
                        <p className={cl.courseTextWrap}>
                            In JavaScript, think of variables as <b>data holders</b>. They can store numbers, text, or
                            complex objects, allowing you to work with information in your code
                            <br/><br/>
                            JavaScript offers <b>three</b> different <b>keywords</b> for declaring variables: var, let,
                            and const
                            <br/><br/>

                            Here's how you declare and use variables:
                        </p>
                        <ol>
                            <li><p><b>var</b> : Old and <b>flexible</b>, it lets you re-declare and reassign variables
                                (It is better to avoid using var)
                            </p></li>
                            <li><p><b>let</b> : Modern and flexible, it allows <b>reassignment</b> but <b>not
                                re-declaration</b></p>
                            </li>
                            <li><p><b>const</b> : Modern and strict, it <b>locks</b> values
                                from <b>both</b> reassignment and
                                re-declaration</p>
                            </li>
                        </ol>


                        <SyntaxHighlighter wrapLongLines={false} language="javascript" style={vs2015}>
                            {'// Using var (older way)\n' +
                            'var message = "Hello, world!";\n' +
                            'message = "Hi there!";\n' +
                            '\n' +
                            '// Using let (modern)\n' +
                            'let age = 25;\n' +
                            'age = 26;\n' +
                            '\n' +
                            '// Using const (modern)\n' +
                            'const pi = 3.14159;'}
                        </SyntaxHighlighter>
                        <p className={cl.courseTextWrap}>
                            <b>*</b> Variable names are <b>case-sensitive</b> and follow rules like starting with a
                            letter or
                            symbols like _ or $. They <b>can contain</b> letters, numbers, underscores, and dollar
                            signs.
                        </p>
                    </div>
                    {user?.subscriptionStatus === 'Paid' && <div>
                        <div className={cl.paragraphWrap}>
                            <div className={cl.courseTitleWrap}>
                                <TitleNumbered title={'Condition statements'} number={'3'}/>
                            </div>
                            <p className={cl.courseTextWrap}>
                                Conditional statements, often referred to as <b>if statements</b>, are a fundamental
                                building block
                                of programming. They allow your code to <b>make decisions</b> based on
                                certain <b>conditions</b><br/><br/>
                                Here's a simple structure of an if statement in JavaScript:
                            </p>


                            <SyntaxHighlighter wrapLongLines={false} language="javascript" style={vs2015}>
                                {
                                    'if (condition) {\n' +
                                    '  // Code to execute if the condition is true\n' +
                                    '} else {\n' +
                                    '  // Code to execute if the condition is false\n' +
                                    '}'}
                            </SyntaxHighlighter>
                            <ul>
                                <li><p><b>if</b> : This is the keyword that <b>initiates</b> the conditional statement
                                </p>
                                </li>
                                <li><p><b>condition</b> : This is an expression that <b>evaluates</b> to either true or
                                    false.
                                    If the
                                    condition is
                                    <b> true</b>, the code inside the <b>first block</b> will execute; otherwise, the
                                    code
                                    inside the <b>else
                                        block</b> (if present) will execute</p>
                                </li>
                                <li><p><b>{'{ ... }'}</b> : These curly braces define the <b>blocks of code </b>associated
                                    with the <b>if</b> and <b>else</b> conditions</p>
                                </li>
                            </ul>
                            <p className={cl.courseTextWrap}>
                                <b>* </b> In JavaScript, =, ==, and === are operators used
                                for <b>different</b> purposes,
                                and they have
                                <b> distinct</b> functionalities.
                                <b> =</b> is for <b>assignment</b>, not comparison. <b>==</b> checks for <b>equality
                                after
                                type
                                coercion</b> , which can lead to unexpected results when comparing values of different
                                types.
                                <b>===</b> checks for <b>strict equality</b>, considering <b>both</b> value and data
                                type,
                                and is <b>recommended</b> for
                                most equality comparisons to avoid unexpected behavior
                            </p>
                            <p className={cl.courseTextWrap}>
                                Conditional statements are essential tools for
                                adding <b>interactivity</b> and <b>decision-making</b> to
                                your web applications. They allow you to create code that responds intelligently to
                                <b> different situations</b>, making your websites more dynamic and user-friendly
                            </p>
                        </div>
                        <div className={cl.paragraphWrap}>
                            <div className={cl.courseTitleWrap}>
                                <TitleNumbered title={'Data types'} number={'4'}/>
                            </div>
                            <p className={cl.courseTextWrap}>
                                These <b>data types</b> are the foundation upon which you'll build your web
                                applications,
                                each
                                serving a specific purpose. You don't need to remember but <b>get the general
                                idea</b> of
                                each is
                                important
                            </p>
                            <ul className={localCl.dataTypesList}>
                                <li><p><b className={cl.tag}>Numbers</b> represent numerical values. They can
                                    be <b>integers</b> (whole numbers) or
                                    floating-point numbers <b>(decimals)</b></p>
                                    <SyntaxHighlighter wrapLongLines={false} language="javascript" style={vs2015}>
                                        {'let age = 25;\n' +
                                        'let price = 19.99;'}
                                    </SyntaxHighlighter>
                                </li>
                                <li><p><b className={cl.tag}>Strings</b> are <b>sequences of characters</b>, such as
                                    text.
                                    They are enclosed in single
                                    ('
                                    '), double (" "), or backticks (` `) quotes</p>
                                    <SyntaxHighlighter wrapLongLines={false} language="javascript" style={vs2015}>
                                        {'let name = "Alice";\n' +
                                        'let message = \'Welcome to our website!\';'}
                                    </SyntaxHighlighter>
                                </li>
                                <li><p><b className={cl.tag}>Booleans</b> have only two possible
                                    values: <b>true</b> or <b>false</b>. They are often used for
                                    conditions and logical operations</p>
                                    <SyntaxHighlighter wrapLongLines={false} language="javascript" style={vs2015}>
                                        {'let isLogged = true;\n' +
                                        'let hasPermission = false;'}
                                    </SyntaxHighlighter>
                                </li>
                                <li><p><b className={cl.tag}>Arrays</b> store collections of values, allowing you
                                    to <b>group</b> related items
                                    together.
                                    They are ordered and can hold <b>various</b> data types</p>
                                    <SyntaxHighlighter wrapLongLines={false} language="javascript" style={vs2015}>
                                        {'let colors = ["red", "green", "blue"];\n' +
                                        'let mixedArray = [1, "apple", true, 5];'}
                                    </SyntaxHighlighter>
                                    <p className={cl.mt}>
                                        Each element in an array has a <b>numeric index</b>, starting from <b>0</b> for
                                        the first element.
                                        You can <b>access</b> individual elements by referring to their index</p>
                                    <SyntaxHighlighter wrapLongLines={false} language="javascript" style={vs2015}>
                                        {'console.log(colors[0]); // Outputs "red"\n' +
                                        'console.log(mixedArray[2]); // Outputs true'}
                                    </SyntaxHighlighter>
                                    <p className={cl.mt}>You can get different information about array
                                        using <b>properties:</b></p>
                                    <SyntaxHighlighter wrapLongLines={false} language="javascript" style={vs2015}>
                                        {'console.log(fruits.length); // Outputs 3 (there are 3 elements in the fruits array)\n'}
                                    </SyntaxHighlighter>
                                    <p className={cl.mt}><b>Push</b> and <b>Pop</b> : The push() method <b>adds</b> an
                                        element to the end of an
                                        array, while pop() <b>removes </b>the last element</p>
                                    <SyntaxHighlighter wrapLongLines={false} language="javascript" style={vs2015}>
                                        {'colors.push("yellow"); // Adds "yellow" to the end of the array\n' +
                                        'let lastColor = colors.pop(); // Removes and returns the last element ("yellow")'}
                                    </SyntaxHighlighter>
                                </li>
                                <li><p>If you grasp arrays in JavaScript, <b className={cl.tag}>objects</b> are a
                                    natural
                                    next step. Objects,
                                    like arrays, organize data, but they do so with <b>named keys</b></p>
                                    <SyntaxHighlighter wrapLongLines={false} language="javascript" style={vs2015}>
                                        {'let person1 = {\n' +
                                        '    firstName: "John",\n' +
                                        '    lastName: "Doe",\n' +
                                        '    age: 30,\n' +
                                        '};'}
                                    </SyntaxHighlighter>
                                    <p className={cl.mt}>You can <b>access</b> properties using keys, like this:</p>
                                    <SyntaxHighlighter wrapLongLines={false} language="javascript" style={vs2015}>
                                        {'console.log(person1.firstName); // Outputs "John"\n'}
                                    </SyntaxHighlighter>
                                    <p className={cl.mt}>You can <b>mix</b> arrays and objects, allowing you to
                                        represent
                                        diverse
                                        and structured data
                                        in your code</p>
                                    <SyntaxHighlighter wrapLongLines={false} language="javascript" style={vs2015}>
                                        {'let person = {\n' +
                                        '    name: "John",\n' +
                                        '    hobbies: ["Reading", "Hiking"],\n' +
                                        '    address: {\n' +
                                        '        street: "123 Main St",\n' +
                                        '        city: "New York"\n' +
                                        '    }\n' +
                                        '};'}
                                    </SyntaxHighlighter>expand
                                    <p className={cl.mt}>In a nutshell, objects <b></b> on arrays by introducing named
                                        keys.
                                        They're ideal for handling <b>structured</b> data and creating
                                        dynamic, <b>nested</b> structures
                                        for web development</p>


                                </li>
                                <li><p><b className={cl.tag}>Null</b> represents the intentional <b>absence</b> of any
                                    value,
                                    while <b className={cl.tag}>undefined </b>signifies a
                                    variable that <b>hasn't been assigned</b> a value yet</p>
                                    <SyntaxHighlighter wrapLongLines={false} language="javascript" style={vs2015}>
                                        {'let emptyValue = null;\n' +
                                        'let notDefined;'}
                                    </SyntaxHighlighter>
                                </li>
                                <li>
                                    <p><b className={cl.tag}>Functions</b> are <b>reusable</b> sets of operations or <b>blocks
                                        of code</b>
                                        that can be
                                        executed when called. They
                                        can take input <b>(parameters)</b> and <b>return</b> a result<br/><br/>

                                        JavaScript offers <b>three</b> primary methods: function declaration, function
                                        expression, and arrow functions.<br/><br/>

                                        <b>Function declarations</b> use the <b>"function"</b> keyword
                                        followed by a <b>name</b> and a <b>code block</b>, allowing them to be
                                        used <b>before </b>
                                        or after their <b>declaration </b>in the code, thanks to
                                        hoisting
                                    </p>
                                    <SyntaxHighlighter wrapLongLines={false} language="javascript" style={vs2015}>
                                        {'function add(x, y) {\n' +
                                        '    return x + y;\n' +
                                        '}'}
                                    </SyntaxHighlighter>
                                    <p className={cl.mt}>
                                        A <b>function expression</b> defines a function as part of an expression, often
                                        by
                                        <b>assigning it to a variable</b>. Unlike function declarations, function
                                        expressions must
                                        be defined before they are used in the code, as they are not hoisted. They are
                                        useful when you need to create functions <b>dynamically</b> or <b>as
                                        arguments</b> to other
                                        functions
                                    </p>
                                    <SyntaxHighlighter wrapLongLines={false} language="javascript" style={vs2015}>
                                        {'const greet = function() {\n' +
                                        '    console.log("Hello!");\n' +
                                        '};'}
                                    </SyntaxHighlighter>
                                    <p className={cl.mt}>
                                        <b>Arrow functions</b> are a concise and modern way to write functions. They use
                                        the
                                        <b>=></b>
                                        notation and have implicit return, which means you can <b>omit</b> the return
                                        keyword for
                                        single-expression functions. These are like a <b>shortcut for simple tasks</b>
                                    </p>
                                    <SyntaxHighlighter wrapLongLines={false} language="javascript" style={vs2015}>
                                        {'const add = (x, y) => x + y;'}
                                    </SyntaxHighlighter>
                                    <p className={cl.mt}>
                                        As you learn and practice more, you'll understand which way works best for
                                        different
                                        situations. For now, just remember that functions help you <b>not to repeat
                                        yourself</b>,
                                        and there are a <b>few</b> ways to create them
                                    </p>
                                </li>
                            </ul>
                        </div>
                        <div className={cl.paragraphWrap}>
                            <div className={cl.courseTitleWrap}>
                                <TitleNumbered title={'Loops'} number={'5'}/>
                            </div>
                            <p className={cl.courseTextWrap}>
                                <b>Loops</b> are the next step in web development. They allow you
                                to <b>automate</b> repetitive
                                tasks efficiently and precisely
                            </p>
                            <ol>
                                <li>
                                    <p className={cl.courseTextWrap}><b className={cl.tag}>For</b> : Use when you know
                                        <b> how many</b> times an action should repeat</p>
                                    <SyntaxHighlighter wrapLongLines={false} language="javascript" style={vs2015}>
                                        {'for (let i = 0; i < 10; i++) {\n' +
                                        '    console.log("Iteration " + i);\n' +
                                        '}'}
                                    </SyntaxHighlighter>
                                    <p className={cl.courseTextWrap + ' ' + cl.mb}>
                                        <b>Outcome:</b> This loop runs <b>five</b> times, printing "Iteration 0" to
                                        "Iteration 4"
                                        in the console
                                    </p>
                                    <SyntaxHighlighter wrapLongLines={false} language="javascript" style={vs2015}>
                                        {'for (let i = 4; i >= 0; i-=2) {\n' +
                                        '    console.log("Iteration " + i);\n' +
                                        '}'}
                                    </SyntaxHighlighter>
                                    <p className={cl.courseTextWrap}>
                                        <b>Outcome:</b> This loop runs <b>three</b> times, printing "Iteration
                                        4","Iteration
                                        2","Iteration 0" in the console
                                    </p>
                                </li>
                                <li>
                                    <p className={cl.courseTextWrap}>
                                        <b className={cl.tag}>While</b> : Ideal when you want an action to repeat <b>as
                                        long as a specific
                                        condition is met</b>
                                    </p>
                                    <SyntaxHighlighter wrapLongLines={false} language="javascript" style={vs2015}>
                                        {'let count = 0;\n' +
                                        'while (count < 5) {\n' +
                                        '    console.log("Count: " + count);\n' +
                                        '    count++;\n' +
                                        '}'}
                                    </SyntaxHighlighter>
                                    <p className={cl.courseTextWrap}>
                                        <b>Outcome:</b> The loop runs until count reaches <b>5</b>, printing "Count: 0"
                                        to
                                        "Count:
                                        4"
                                    </p>
                                </li>
                                <li>
                                    <p className={cl.courseTextWrap}>
                                        <b className={cl.tag}>For...of</b> : Great for iterating
                                        through arrays and other <b>iterable objects</b>
                                    </p>
                                    <SyntaxHighlighter wrapLongLines={false} language="javascript" style={vs2015}>
                                        {'let fruits = ["Apple", "Banana", "Cherry"];\n' +
                                        'for (let fruit of fruits) {\n' +
                                        '    console.log(fruit);\n' +
                                        '}'}
                                    </SyntaxHighlighter>
                                    <p className={cl.courseTextWrap}>
                                        <b>Outcome:</b> This loop iterates through the fruits array,
                                        printing <b>each</b> fruit.
                                    </p>
                                </li>
                                <li>
                                    <p className={cl.courseTextWrap}>
                                        <b className={cl.tag}>For...in</b> : Useful for iterating over <b>object </b>
                                        properties
                                    </p>
                                    <SyntaxHighlighter wrapLongLines={false} language="javascript" style={vs2015}>
                                        {'let person = {\n' +
                                        '    name: "Alice",\n' +
                                        '    age: 30,\n' +
                                        '    job: "Developer"\n' +
                                        '};\n' +
                                        'for (let key in person) {\n' +
                                        '    console.log(key + ": " + person[key]);\n' +
                                        '}'}
                                    </SyntaxHighlighter>
                                    <p className={cl.courseTextWrap}>
                                        <b>Outcome:</b> The loop prints "name: Alice", "age: 30", and "job: Developer"
                                        by
                                        accessing <b>object properties</b>
                                    </p>
                                </li>
                                <li>
                                    <p className={cl.courseTextWrap}>
                                        <b className={cl.tag}>Do...while</b> : Executes an action <b>at least
                                        once</b> and
                                        then
                                        repeats it as long as a condition is met
                                    </p>
                                    <SyntaxHighlighter wrapLongLines={false} language="javascript" style={vs2015}>
                                        {'let temperature = 35;\n' +
                                        'do {\n' +
                                        '    console.log("Cooling down... Temperature: " + temperature);\n' +
                                        '    temperature--;\n' +
                                        '} while (temperature > 25);'}
                                    </SyntaxHighlighter>
                                    <p className={cl.courseTextWrap}>
                                        <b>Outcome:</b> The loop runs while temperature is above 25, decrementing the
                                        temperature with each iteration
                                    </p>
                                </li>
                            </ol>
                        </div>
                        <div className={cl.paragraphWrap}>
                            <div className={cl.courseTitleWrap}>
                                <TitleNumbered title={'Array functions'} number={'6'}/>
                            </div>
                            <p className={cl.courseTextWrap}>
                                For novice web developers delving into JavaScript, mastering these <b>array
                                methods—callbacks</b>,
                                forEach, map, filter, and reduce—will raise your coding skills. These tools transform
                                how
                                you manipulate and process arrays, making your code cleaner and more efficient
                            </p>
                            <p className={cl.courseTextWrap}>
                                First we start with a main term - <b>callback</b>. Callbacks are <b>functions</b> you
                                pass <b>as arguments</b> to
                                other functions. They execute after the
                                parent function completes its task. Used in various array methods to customize behavior
                            </p>
                            <SyntaxHighlighter wrapLongLines={false} language="javascript" style={vs2015}>
                                {'function calculate(x, y, operation) {\n' +
                                '    //operation is a callback function needs to receive \n' +
                                '    let result = operation(x, y);\n' +
                                '    console.log("Result:", result);\n' +
                                '}\n' +
                                '\n' +
                                'function add(a, b) {\n' +
                                '    return a + b;\n' +
                                '}\n' +
                                '\n' +
                                'calculate(5, 3, add);\n' +
                                '/* "add" function is a callback for calculate function\n' +
                                'We pass "add" function as an argument */'}
                            </SyntaxHighlighter>
                            <ol>
                                <li>
                                    <p className={cl.courseTextWrap}>
                                        <b className={cl.tag}>forEach</b> Iterates through an
                                        array, executing a <b>provided callback</b> on each element</p>
                                    <SyntaxHighlighter wrapLongLines={false} language="javascript" style={vs2015}>
                                        {'let fruits = ["Apple", "Banana", "Cherry"];\n' +
                                        'fruits.forEach(function(fruit) {\n' +
                                        '    console.log("I love " + fruit + "!");\n' +
                                        '});'}
                                    </SyntaxHighlighter>
                                    <p className={cl.courseTextWrap + ' ' + cl.mb}>
                                        <b>Outcome:</b> loops through the fruits array and prints "I love Apple!", "I
                                        love
                                        Banana!", "I love Cherry!"
                                    </p>

                                </li>
                                <li>
                                    <p className={cl.courseTextWrap}>
                                        <b className={cl.tag}>Map</b> : creates a <b>new</b> array by <b>applying</b> a
                                        function (callback) to each
                                        element of the original array
                                    </p>
                                    <SyntaxHighlighter wrapLongLines={false} language="javascript" style={vs2015}>
                                        {'let numbers = [1, 2, 3, 4, 5];\n' +
                                        'let doubledNumbers = numbers.map(function(number) {\n' +
                                        '    return number * 2;\n' +
                                        '});'}
                                    </SyntaxHighlighter>
                                    <p className={cl.courseTextWrap}>
                                        <b>Outcome:</b> produces a <b>new array</b> with doubled numbers: [2, 4, 6, 8,
                                        10]
                                    </p>
                                </li>
                                <li>
                                    <p className={cl.courseTextWrap}>
                                        <b className={cl.tag}>filter</b> : Creates a new array with elements that <b>meet
                                        a
                                        specified condition</b>
                                    </p>
                                    <SyntaxHighlighter wrapLongLines={false} language="javascript" style={vs2015}>
                                        {'let numbers = [1, 2, 3, 4, 5];\n' +
                                        'let evenNumbers = numbers.filter(function(number) {\n' +
                                        '    return number % 2 === 0;\n' +
                                        '});'}
                                    </SyntaxHighlighter>
                                    <p className={cl.courseTextWrap}>
                                        <b>Outcome:</b> Yields a new array containing <b>only even </b>numbers: [2, 4]
                                    </p>
                                </li>
                                <li>
                                    <p className={cl.courseTextWrap}>
                                        <b className={cl.tag}>reduce</b> : Compress an array to a single value by
                                        applying a
                                        function that accumulates results
                                    </p>
                                    <SyntaxHighlighter wrapLongLines={false} language="javascript" style={vs2015}>
                                        {'let numbers = [1, 2, 3, 4, 5];\n' +
                                        'let sum = numbers.reduce(function(accumulator, currentNumber) {\n' +
                                        '    return accumulator + currentNumber;\n' +
                                        '}, 0); // Initial value is 0'}
                                    </SyntaxHighlighter>
                                    <p className={cl.courseTextWrap}>
                                        <b>Outcome:</b> Computes the <b>sum</b> of all numbers in the array, resulting
                                        in 15
                                    </p>
                                </li>

                            </ol>
                        </div>
                        <div className={cl.paragraphWrap}>
                            <div className={cl.courseTitleWrap}>
                                <TitleNumbered title={'Document Object Model'} number={'7'}/>
                            </div>
                            <p className={cl.courseTextWrap}>
                                For web developers, JavaScript acts as the <b>bridge</b> between code and the web page
                                itself. It
                                grants you the power to not only access but also <b>manipulate</b> HTML elements and
                                their
                                attributes through the Document Object Model <b>(DOM)</b>
                            </p>
                            <p className={cl.courseTextWrap}>
                                The DOM is a representation of the webpage's structure, creating a <b>tree-like
                                structure of
                                HTML</b> elements. JavaScript uses this tree to interact with and modify web
                                content <b>dynamically</b>
                            </p>
                            <p className={cl.courseTextWrap}>
                                JavaScript lets you <b>select</b> HTML elements using their unique IDs, classes, tags,
                                or
                                other
                                <b> attributes</b>. For instance:
                            </p>
                            <SyntaxHighlighter wrapLongLines={false} language="javascript" style={vs2015}>
                                {'let myElement = document.getElementById("myId"); // Access by ID\n' +
                                'let buttons = document.getElementsByClassName("btn"); // Access by class\n' +
                                'let paragraphs = document.getElementsByTagName("p"); // Access by tag'}
                            </SyntaxHighlighter>
                            <p className={cl.courseTextWrap}>
                                The <b>easiest</b> way to access the HTML elements are querySelector and
                                querySelectorAll<br/><br/> <b className={cl.tag}>querySelector</b> allows you to
                                pinpoint
                                a <b>single</b> HTML element using <b>CSS-like
                                selectors</b>.
                                This method returns the first element that matches your query. It's your go-to choice
                                when
                                you need to access a <b>specific</b> element by its ID, class, or tag
                            </p>
                            <SyntaxHighlighter wrapLongLines={false} language="javascript" style={vs2015}>
                                {'let firstButton = document.querySelector(".btn");'}
                            </SyntaxHighlighter>
                            <p className={cl.courseTextWrap}>
                                <b className={cl.tag}>querySelectorAll</b> takes the convenience of querySelector a step
                                further. It enables you to
                                select <b>multiple</b> elements that match your query and returns them as
                                a <b>NodeList</b>,
                                which is an
                                array-like object. This method is perfect when you want to work with a <b>group</b> of
                                elements
                                sharing the same class or tag
                            </p>
                            <SyntaxHighlighter wrapLongLines={false} language="javascript" style={vs2015}>
                                {'let buttons = document.querySelectorAll(".btn");\n'}
                            </SyntaxHighlighter>
                            <p className={cl.courseTextWrap}>
                                Once you've successfully accessed an HTML element in JavaScript, you unlock a world of
                                possibilities. You can <b>change the content</b> within the element, <b>create</b> new
                                elements inside it,
                                access its <b>parent</b> element, and perform various other manipulations. This
                                capability
                                forms
                                the foundation of web interactivity, enabling you to craft dynamic and engaging user
                                experiences that respond to user actions and inputs
                            </p>
                        </div>
                        <div className={cl.paragraphWrap}>
                            <div className={cl.courseTitleWrap}>
                                <TitleNumbered title={'Event listening'} number={'8'}/>
                            </div>
                            <p className={cl.courseTextWrap}>
                                For novice web developers, understanding <b>event listening</b> is a gateway to crafting
                                dynamic
                                and responsive web applications. Events are <b>user actions</b> (like clicks, keyboard
                                input, or
                                mouse movements) that <b>trigger responses</b> in your JavaScript code. Let's dive into
                                the
                                world
                                of event listening:
                            </p>
                            <p className={cl.courseTextWrap}>
                                JavaScript allows you to <b>"listen"</b> for specific <b>events</b> on HTML elements,
                                such
                                as buttons,
                                forms, or the entire document. You can then respond to these events with <b>custom
                                actions</b>.There's a multitude of event types, from mouse events (like clicks and
                                hovers)
                                to
                                keyboard events (like key presses) and form events (like submissions). Understanding
                                which
                                event to listen for is crucial
                            </p>
                            <p className={cl.courseTextWrap}>
                                You attach event listeners to elements using JavaScript's <b
                                className={cl.tag}>addEventListener</b> method. This
                                method takes two arguments: the event type (e.g., "click" or "keydown") and a function
                                to
                                execute when the event occurs (callback)
                            </p>
                            <SyntaxHighlighter wrapLongLines={false} language="javascript" style={vs2015}>
                                {'// Add a click event listener to a button\n' +
                                'const myButton = document.getElementById("myButton");\n' +
                                'myButton.addEventListener("click", function() {\n' +
                                '    alert("Button clicked!");\n' +
                                '});'}
                            </SyntaxHighlighter>
                        </div>
                        <div className={cl.paragraphWrap}>
                            <div className={cl.courseTitleWrap}>
                                <TitleNumbered title={'setTimeout and setInterval'} number={'9'}/>
                            </div>
                            <p className={cl.courseTextWrap}>
                                In web development, setTimeout and setInterval are essential tools for
                                managing <b>time-based</b>
                                actions <br/><br/>
                                <b className={cl.tag}>setTimeout</b> : Use it to execute a function <b>after a specified
                                delay</b>:
                            </p>
                            <SyntaxHighlighter wrapLongLines={false} language="javascript" style={vs2015}>
                                {'setTimeout(function() {\n' +
                                '    console.log("Delayed message");\n' +
                                '}, 2000); // Executes after 2 seconds (2000 milliseconds)'}
                            </SyntaxHighlighter>
                            <p className={cl.courseTextWrap}>
                                <b className={cl.tag}>setInterval</b> : Employ this for repeatedly running a function at
                                <b> defined intervals</b>
                            </p>
                            <SyntaxHighlighter wrapLongLines={false} language="javascript" style={vs2015}>
                                {'setInterval(function() {\n' +
                                '    console.log("Interval message");\n' +
                                '}, 1000); // Repeats every 1 second (1000 milliseconds)'}
                            </SyntaxHighlighter>

                        </div>
                    </div>}

                </div>
            </div>
            {user?.subscriptionStatus !== 'Paid' && <div className={cl.banner}><div className={cl.blur}></div><div className={cl.offerSmall}><OfferSmall/></div></div>}
        </div>
    );
};

export default JsGuide;