import React from 'react';
import cl from "./RecoverConfirmPopup.module.css";
import ResetIcon from "../../../shared/UI/icons/ResetIcon";
import RecoverPassword from "../../../features/RecoverPassword/RecoverPassword";
import CancelButton from "../../../shared/UI/CancelButton/CancelButton";
import Button_lilac from "../../../shared/UI/Button_lilac/Button_lilac";

const RecoverConfirmPopup = ({email, setPopupIsVisible}) => {

    return (
        <div className={cl.wrap} onClick={()=>setPopupIsVisible(false)}>
            <div className={cl.popupWrap} onClick={(e)=>e.stopPropagation()}>
                <div className={cl.titleWrap}>
                    <h4 className={cl.title}>Are you sure you want to reset your password? </h4>
                    <span className={cl.resetIcon}><ResetIcon/></span>
                </div>
                <h5 className={cl.text}>We will send a new password on your email</h5>
                <div className={cl.buttonsWrap}>
                    <RecoverPassword email={email}>
                        <Button_lilac>
                            Recover password
                        </Button_lilac>
                    </RecoverPassword>
                    <CancelButton onClick={()=>setPopupIsVisible(false)}>
                        Cancel
                    </CancelButton>
                </div>
            </div>
        </div>
    );
};

export default RecoverConfirmPopup;