import React, {useEffect, useState} from 'react';
import cl from './Login.module.css'
import {Link, useNavigate} from "react-router-dom";
import Error from "../../shared/UI/Error/Error";
import RecoverPassword from "../../features/RecoverPassword/RecoverPassword";
import PasswordInput from "../../entities/PasswordInput/PasswordInput";
import EmailConfirmPopup from "../../widgets/popups/EmailConfirmPopup/EmailConfirmPopup";
import LoginButton from "../../features/LoginButton/LoginButton";
import RecoverPopup from "../../widgets/popups/RecoverPopup/RecoverPopup";
import Button_lilac from "../../shared/UI/Button_lilac/Button_lilac";
import Button_lilac_text from "../../shared/UI/Button_lilac_text/Button_lilac_text";

const Login = ({}) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [loginErrors, setLoginErrors] = useState(null)
    const navigate = useNavigate();

    const [confirmPopupIsVisible, setConfirmPopupIsVisible] = useState(false)
    const [recoverPopupIsVisible, setRecoverPopupIsVisible] = useState(false)


    async function handleLogin() {
        navigate('/me')
    }

    useEffect(() => {
        setConfirmPopupIsVisible(loginErrors?.find(error => error.place === 'emailActivation'))
        if (loginErrors && loginErrors.length === 0) {
            handleLogin()
        }
    }, [loginErrors])
    return (

        <div className={cl.wrap + " flexCenter"}>
            {confirmPopupIsVisible &&
            <EmailConfirmPopup email={email} password={password} setPopupIsVisible={setConfirmPopupIsVisible}/>}
            {recoverPopupIsVisible &&
            <RecoverPopup email={email} setPopupIsVisible={setRecoverPopupIsVisible}/>}

            <form className={cl.form} autoComplete='on'>
                <h1 className={cl.title}>
                    Log in to Wripet
                </h1>
                <div className={cl.inputsWrap}>
                    <input name="email" id="email" type="email" placeholder='Email or username'
                           onChange={e => setEmail((e.target.value))}/>
                    <Error errors={loginErrors} type="email"/>
                    <PasswordInput setPassword={setPassword} errors={loginErrors}/>
                </div>
                <div className={cl.submitButtonWrap}>
                    <LoginButton email={email} password={password} setErrors={setLoginErrors}>
                        <Button_lilac>
                            Log in
                        </Button_lilac>
                    </LoginButton>
                </div>
                <div className={cl.sendNewPasswordButtonWrap}>
                    <RecoverPassword email={email} setRecoverPopupIsVisible={setRecoverPopupIsVisible}>
                        <Button_lilac_text>
                            Recover password
                        </Button_lilac_text>
                    </RecoverPassword>
                </div>
                {/*TODO <LoginExternalWay/>*/}
                <p className={cl.signupLinkWrap}>
                    Not a member yet? <Link to={'/signup'} className={cl.signupLink}>Sign up for free</Link>
                </p>
            </form>
        </div>
    );
};

export default Login;