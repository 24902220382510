import TryProjectTab from "../modules/projectTabs/TryProjectTab/TryProjectTab";
import GuideTab from "../modules/projectTabs/GuideTab/GuideTab";
import HtmlTab from "../modules/projectTabs/HtmlTab/HtmlTab";
import CssTab from "../modules/projectTabs/CssTab/CssTab";
import JsTab from "../modules/projectTabs/JsTab/JsTab";
import DefaultCodeTab from "../../../widgets/Project/ui/DefaultCodeTab/DefaultCodeTab";
import React from "react";

export const tabListForBasicWebStack = (name, demoData, html, css, js, guide) => [
    {title: 'Try project', id: 'try-project', component: <TryProjectTab data={demoData}/>, tabType: 'guide'},
    {title: 'Guide', id: 'guide', component: <GuideTab data={guide}/>, tabType: 'guide'},
    {title: 'HTML', id: 'html', component: <HtmlTab name={name} code={html.code}/>, tabType : 'code'},
    {title: 'CSS', id: 'css', component: <CssTab name={name} code={css.code}/>, tabType : 'code'},
    {title: 'JS', id: 'js', component: <JsTab code={js?.code}/>, tabType:'code'},
    {title: '', id: 'icon', component: <DefaultCodeTab/>, tabType: 'code'},
];