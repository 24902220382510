import React from 'react';
import cl from './Hero.module.css'
import Sticks from "../../../../shared/UI/Sticks/Sticks";
import HamburgerIcon from "../../../../shared/UI/siteIcons/HamburgerIcon/HamburgerIcon";
import LoaderIcon from "../../../../shared/UI/siteIcons/LoaderIcon/LoaderIcon";
import ToastNotificationsIcon from "../../../../shared/UI/siteIcons/ToastNotificationsIcon/ToastNotificationsIcon";
import ThemeToggler from "../../../../shared/UI/siteIcons/ThemeToggler/ThemeToggler";
import Button_lilac from "../../../../shared/UI/Button_lilac/Button_lilac";
import AnchorLink from "react-anchor-link-smooth-scroll";

const Hero = () => {
    return (
        <div className={cl.wrap}>
            <div className={cl.textWrap}>
                <h2 className={cl.title + " text-1 semiBold"}>5 lines of code</h2>
                <p className={cl.subtitle}>Is needed to create your first interactive projects </p>
                <div className={cl.sticksWrap}>
                    <Sticks styles={{
                        width: '4px', leftHeight: '18vh', rightHeight: '18vh', leftStickTop: '80px', rightStickTop: '0',
                        distance: '30px', wrapTop: '15%',
                        wrapLeft: '90%'
                    }}/>
                    <Sticks styles={{
                        width: '4px', leftHeight: '18vh', rightHeight: '18vh', leftStickTop: '0', rightStickTop: '80px',
                        distance: '30px', wrapTop: '65%',
                        wrapLeft: '8%'
                    }}/>
                </div>
                <div className={cl.button}>
                    <AnchorLink href='#about'>
                        <Button_lilac style={{padding: "15px 30px"}}>View more</Button_lilac>
                    </AnchorLink>
                </div>
            </div>

            <div className={cl.siteIcons}>
                <HamburgerIcon style={{top: '22%', left: '7%',}}/>
                <ToastNotificationsIcon style={{top: '11%', left: '64%'}}/>
                <LoaderIcon style={{top: '67%', left: '16%'}}/>
                <ThemeToggler style={{top: '60%', left: '74%'}}/>
            </div>


        </div>
    );
};

export default Hero;