import React, {useEffect, useState} from 'react';
import {useDebounce} from "./useDebounce";

export function useDebouncedSaveToApi (apiMethod, dataToSend, debounceTime, shownIconTime) {
    const debouncedDataToSend = useDebounce(dataToSend, debounceTime)
    const [firstRender, setFirstRender] = useState(true)
    const [showSavedIcon, setShowSavedIcon] = useState(false)
    useEffect(() => {
        if (firstRender) {
            setFirstRender(false)
        } else {
            handleSaveProperty()
        }

        async function handleSaveProperty() {
            apiMethod(debouncedDataToSend)
            setShowSavedIcon(true)
            setTimeout(() => {
                setShowSavedIcon(false)
            }, shownIconTime)
        }

    }, [debouncedDataToSend])
    return [showSavedIcon, debouncedDataToSend ];
}
