import React, {useEffect, useRef, useState} from 'react';
import cl from './FallingObjects.module.css'
import Button_lilac from "../../../../../shared/UI/Button_lilac/Button_lilac";


const FallingObjects = () => {
    const wrapNode = useRef(null)
    const [isRaining, setIsRaining] = useState(true);


    function createHeart() {
        const heart = document.createElement('div');
        heart.classList.add(cl.heart);
        heart.style.left = Math.random() * 100 + '%';
        heart.style.animationDuration = Math.random() * 2 + 3 + 's';
        heart.innerText = '|';
        wrapNode.current?.appendChild(heart);
        setTimeout(() => {
            heart.remove();
        }, 5000)
    }


    useEffect(() => {
        let intervalId
        if (isRaining)
            intervalId = setInterval(()=>{createHeart()}, 30)
        return () => {
            clearInterval(intervalId);
        }
    }, [isRaining])


    return (
        <div ref={wrapNode} className={cl.wrap +(isRaining ? ` ${cl.active}` : '')} >
            <Button_lilac onClick={() => setIsRaining(!isRaining)}>
                Rain
            </Button_lilac>
        </div>
    );
};

export default FallingObjects;