import React from 'react';

const ProjectTabMenu = ({tabMenuData, currentTab, changeCurrentTab, activeClass}) => {
    return (
        <ul>
            {tabMenuData.map((tab) =>
                tab.title && <li key={tab.id}>
                    <button onClick={() => changeCurrentTab(tab.id)}
                            className={currentTab === tab.id ? `${activeClass}` : ''}
                            key={tab.id}>
                        {tab.title}
                    </button>
                </li>
            )}
        </ul>
    );
};

export default ProjectTabMenu;