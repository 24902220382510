import React, {useState} from 'react';
import cl from './HtmlTab.module.css'
import {vs2015} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import SyntaxHighlighter from 'react-syntax-highlighter';

import TitleWithComment from "../../../../../shared/UI/TitleWithComment/TitleWithComment";
import Button_lilac from "../../../../../shared/UI/Button_lilac/Button_lilac";
import SavedIcon from "../../../../../shared/UI/SavedIcon/SavedIcon";

const HtmlTab = ({name, code}) => {
    const [showSavedIcon, setShowSavedIcon] = useState(false)

    async function handleCopyTheCode() {
        await navigator.clipboard.writeText(code);
        if (!showSavedIcon) {
            setShowSavedIcon(true)
            setTimeout(() => setShowSavedIcon(false), 500)
        }
    }

    return (
        <div className={cl.wrap}>
            <div className={cl.titleWithCommentWrap}>
                <TitleWithComment
                    data={{
                        title: 'HTML markup', comment: <div>
                            <div style={{marginBottom: '20px'}}>{name} project</div>
                            <Button_lilac onClick={handleCopyTheCode}>Copy the code</Button_lilac>
                            <SavedIcon showSavedIcon={showSavedIcon}>Copied</SavedIcon></div>
                    }}/>
            </div>

            <SyntaxHighlighter wrapLongLines={true} language="htmlbars" style={vs2015}>
                {code}
            </SyntaxHighlighter>
        </div>
    );
};

export default HtmlTab;