import React, {useRef, useState} from 'react';
import cl from './ChangePasswordPopup.module.css'
import KeyIcon from "../../../shared/UI/icons/KeyIcon";
import CancelButton from "../../../shared/UI/CancelButton/CancelButton";
import {CSSTransition} from "react-transition-group";
import ChangePasswordButton from "../../../features/ChangePasswordButton/ChangePasswordButton";
import PasswordInput from "../../../entities/PasswordInput/PasswordInput";
import Button_lilac_text from "../../../shared/UI/Button_lilac_text/Button_lilac_text";
import Button_lilac from "../../../shared/UI/Button_lilac/Button_lilac";
import SavedIcon from "../../../shared/UI/SavedIcon/SavedIcon";

const ChangePasswordPopup = ({setPopupIsVisible}) => {
    const [errors, setErrors] = useState(null)
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')

    const [showSavedIcon, setShowSavedIcon] = useState(false)
    return (
        <div className={cl.wrap} onClick={()=>setPopupIsVisible(false)}>
            <div className={cl.popupWrap} onClick={(e)=>e.stopPropagation()}>
                <button className={cl.exitButton} onClick={() => setPopupIsVisible(false)}>x</button>
                <span className={cl.keyIcon}><KeyIcon/></span>
                <h4 className={cl.title}>Change your password </h4>

                <div className={cl.inputsWrap}>
                    <PasswordInput errors={errors} setPassword={setOldPassword} errorsType={"oldPassword"}
                                   placeholder={"Old password"}/>
                    <PasswordInput errors={errors} setPassword={setNewPassword} errorsType={"newPassword"}
                                   placeholder={"New password"}/>
                </div>

                <div className={cl.buttonsWrap}>
                    {!showSavedIcon&&
                    <ChangePasswordButton setShowSaved={setShowSavedIcon} setErrors={setErrors} oldPassword={oldPassword}
                                          newPassword={newPassword}>
                        <Button_lilac>
                            Change
                        </Button_lilac>
                    </ChangePasswordButton>}
                    <div className={cl.savedIconWrap}>
                        <SavedIcon  showSavedIcon={showSavedIcon}>Saved</SavedIcon>
                    </div>
                    <CancelButton onClick={() => setPopupIsVisible(false)}>
                        Cancel
                    </CancelButton>
                </div>
            </div>
        </div>
    );
};

export default ChangePasswordPopup;