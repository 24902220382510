import React, {useState} from 'react';
import cl from './PasswordInput.module.css'
import Error from "../../shared/UI/Error/Error";
import PasswordEyeIcon from "../../shared/UI/icons/PasswordEyeIcon";
import {accentColor, darkPrimaryColor} from "../../shared/styles/styles";

const PasswordInput = ({setPassword, errors, placeholder = "Password", errorsType = "password"}) => {
    const [showPassword, setShowPassword] = useState(false)
    function handleEyeClick(e){
        e.preventDefault();
        setShowPassword(!showPassword)
    }
    return (
        <div className={cl.wrap}>
            <div className={cl.inputWrap}>
                <input name="password" type={showPassword ? "text" : "password"} placeholder={placeholder}
                       onChange={e => setPassword((e.target.value))}/>

                {/* TODO Tabindex change */}

                <button className={cl.eyeButton} tabIndex={-1} onClick={(e) => handleEyeClick(e)}>
                    <PasswordEyeIcon fill={showPassword ? accentColor : darkPrimaryColor}/>
                </button>
            </div>
            <Error errors={errors} type={errorsType}/>
        </div>
    );
};

export default PasswordInput;