import React from 'react';
import cl from '../Guide.module.css'
import localCl from './IntroGuide.module.css'
import TitleWithComment from "../../../shared/UI/TitleWithComment/TitleWithComment";
import TitleNumbered from "../../../shared/UI/TitleNumbered/TitleNumbered";
import OfferSmall from "../../../widgets/OfferSmall/OfferSmall";
import {useSelector} from "react-redux";

const IntroGuide = ({}) => {
        const user = useSelector(state => state.userSessionData.userSessionData.userDetails);
        return (
            <div className={cl.wrap + ' ' + localCl.wrap}>
                <div className={cl.courseWrap}>
                    <div className={cl.titleWrap + ' ' + localCl.titleWrap}>
                        <TitleWithComment
                            data={{
                                title: 'Welcome to the course!',
                                comment: 'Here you can find all necessary info about the course'
                            }}/>
                    </div>
                    <div className={cl.paragraphsWrap}>
                        <div className={cl.paragraphWrap}>
                            <div className={cl.courseTitleWrap + ' ' + localCl.paragraphTitle}>
                                <TitleNumbered title={'What approach we recommend '} number={1}/>
                            </div>
                            <p className={cl.courseTextWrap}>
                                <b>Welcome to the course!</b> As an educational
                                platform, we have also developed a structured plan to guide you through our
                                program<br/><br/>

                                We recommend starting your journey by exploring our <b>projects page</b>. Here, you'll find
                                a unique <b>hint system</b> that provides explanations for each command when you hover over
                                them. These projects are designed to be <b>beginner-friendly</b>, and even if you have no
                                prior knowledge, you'll be able to grasp the <b>general concepts</b><br/><br/>

                                Please keep in mind that this initial step is meant to give you an overview of what you'll
                                be learning in our guides on <b>HTML</b>, <b>CSS</b>, and <b>JavaScript</b>. The projects
                                are simple, and you don't need to delve too deep into the details at this stage. They serve
                                as a way for you to see <b>real working code</b> from practical applications<br/><br/>

                                The next step is to read our <b>guides</b>. We suggest starting with <b>HTML</b>, then
                                moving on to <b>CSS</b>, and finally, diving into the <b>JavaScript guide</b>. Our guides
                                are intentionally designed not to overwhelm you with excessive information. In the age of
                                the <b>internet</b> and <b>artificial intelligence</b>, it's more important to obtain <b>abstract
                                knowledge</b> rather than memorizing every detail upfront<br/><br/>

                                <b>Professionals</b> and <b>specialists</b> often need to know intricate details to plan and
                                execute large and complex systems. However, through practice, you'll naturally acquire
                                more <b>in-depth knowledge</b>. Think of it like learning to cook: you don't need to master
                                every recipe or know the complete history of a cuisine. Understanding <b>fundamental cooking
                                techniques</b> allows you to experiment and create your own dishes<br/><br/>

                                Similarly, in <b>web development</b>, the focus is on understanding the <b>core
                                principles</b> initially. When taking notes, aim to grasp the <b>general ideas</b> and note
                                down one or two examples. Don't hesitate to <b>search for more information online</b> or ask
                                our AI if you need clarification. Remember, it's okay if you don't remember every single
                                detail. The rest of the <b>theory</b> will become clearer as you progress<br/><br/>

                                The final step is to revisit the <b>projects page</b>. This time, don't rush into writing
                                code from scratch. Instead, go through the guide, <b>copy and paste code snippets</b>,
                                and <b>experiment</b> with them. Make changes to parameters to gain a deeper understanding.
                                After experimenting, challenge yourself to implement the code without looking at the guide,
                                or even start from scratch if you feel up to it. This step is optional
                                but <b>beneficial</b> because the projects you'll find here are similar to those used in <b>modern
                                websites</b>, and you should be <b>confident</b> in your ability to write them<br/><br/>

                                We're excited to be part of your <b>learning journey</b>, and we're here to support you
                                every step of the way!
                            </p>
                        </div>
                        <div className={cl.paragraphWrap}>
                            <div className={cl.courseTitleWrap + ' ' + localCl.paragraphTitle}>
                                <TitleNumbered title={'Easiest way to run and test the code'} number={2}/>
                            </div>
                            <p className={cl.courseTextWrap}>
                                The easiest way to run code is by using online <b>Integrated Development Environment
                                (IDE) </b>
                                services. These platforms eliminate the need to download software, create files, or manage
                                complex configurations. You can simply <b>copy the required code</b>, paste it into the
                                appropriate tab, configure your settings, and run your code. The result is displayed in a
                                small window, simulating a real website. One notable example of such an online code
                                playground is <b>JSFiddle</b><br/><br/>

                                This approach simplifies the coding process, making
                                it <b>accessible</b> and <b>convenient</b> for
                                learners. It allows you to <b>experiment</b> with code, test your ideas, and see the results
                                in
                                real-time without the hassle of traditional development environments
                            </p>
                        </div>
                        <div className={cl.paragraphWrap}>
                            <div className={cl.courseTitleWrap + ' ' + localCl.paragraphTitle}>
                                <TitleNumbered title={'Future education'} number={3}/>
                            </div>
                            <p className={cl.courseTextWrap}>
                                After becoming proficient in creating web projects using HTML, CSS, and pure JavaScript, the
                                next step in web development typically involves <b>expanding</b> your skill set and
                                knowledge to
                                work with more <b>advanced</b> technologies and tools. Here are some <b>key areas</b> to
                                consider:
                            </p>
                            <ul>
                                <li>
                                    <p>
                                        <b className={cl.tag}>Version Control</b> : Master version control systems like
                                        Git and platforms like
                                        GitHub or GitLab. These tools are essential for collaboration, code management,
                                        and tracking changes in your web development projects
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b className={cl.tag}>Responsive Design</b> : Continue to enhance your knowledge
                                        of responsive web design
                                        principles to ensure your applications work well on various screen sizes and
                                        devices
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b className={cl.tag}>Front-End Frameworks</b> : Explore popular front-end
                                        frameworks like React, Angular,
                                        or Vue.js. These frameworks simplify the development of dynamic and interactive
                                        web applications, making it easier to manage complex user interfaces and handle
                                        data efficiently
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b className={cl.tag}>Front-End Frameworks</b> : Explore popular front-end
                                        frameworks like React, Angular,
                                        or Vue.js. These frameworks simplify the development of dynamic and interactive
                                        web applications, making it easier to manage complex user interfaces and handle
                                        data efficiently
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b className={cl.tag}>Build Tools</b> : Learn about build tools like Webpack,
                                        Babel, and Gulp. These tools
                                        help automate tasks like bundling, transpiling, and optimizing your code, making
                                        development more efficient
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b className={cl.tag}>API Integration</b> : Explore how to integrate third-party
                                        APIs into your projects to
                                        access external services and data. Popular examples include social media APIs,
                                        payment gateways, and mapping APIs
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b className={cl.tag}>Back-End Development</b> : Learn a server-side programming
                                        language and a back-end
                                        framework. Common choices include Node.js with Express.js (JavaScript), Python
                                        with Django or Flask, Ruby on Rails (Ruby), or ASP.NET (C#). This enables you to
                                        build server-side logic, handle databases, and create APIs to support your web
                                        applications
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b className={cl.tag}>Database Management</b> : Gain proficiency in working with
                                        databases. Understand
                                        relational databases like MySQL, PostgreSQL, or SQL Server, as well as NoSQL
                                        databases like MongoDB or Firebase. Learn how to design and optimize database
                                        schemas, perform CRUD operations, and work with data effectively
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b className={cl.tag}>Package Managers</b> : Familiarize yourself with package
                                        managers such as npm (Node
                                        Package Manager) or yarn. They facilitate the installation, management, and
                                        sharing of third-party libraries and dependencies for your projects
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b className={cl.tag}>Testing and Debugging</b> : Develop skills in testing and
                                        debugging your web
                                        applications. Learn how to write unit tests, conduct integration testing, and
                                        use debugging tools effectively
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b className={cl.tag}>Deployment and Hosting</b> : Understand the deployment
                                        process, including server
                                        configuration, domain management, and hosting options. Services like AWS,
                                        Heroku, Netlify, or Vercel are popular choices for deploying web applications
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b className={cl.tag}>Continuous Integration/Continuous Deployment (CI/CD)</b> :
                                        Implement CI/CD pipelines
                                        to automate the testing and deployment of your applications, ensuring a
                                        streamlined and error-free release process
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b className={cl.tag}>Progressive Web Apps (PWAs)</b> : Explore PWAs to create
                                        web applications that offer
                                        a native app-like experience, including offline functionality, push
                                        notifications, and improved performance
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <b className={cl.tag}>Web Accessibility (a11y)</b> : Learn about web accessibility
                                        standards (WCAG) and how
                                        to create inclusive web experiences for users with disabilities
                                    </p>
                                </li>
                            </ul>
                            <p className={cl.courseTextWrap +' '+cl.mt}>
                                In conclusion, our online educational platform offers a <b>unique</b> and <b>innovative</b> approach to
                                learning. We've crafted a structured path to guide you through our programs and help you
                                achieve your learning goals. What sets us apart is our cutting-edge <b>code hint</b> system,
                                designed to simplify complex concepts and enhance your understanding. Join us
                                on this exciting journey of discovery and growth, and let's unlock your full potential
                                together
                            </p>
                        </div>

                    </div>
                    {/* TODO make absolute position relative to body or other way */}
                    {user?.subscriptionStatus !== 'Paid' && <div className={cl.blur}></div>}
                </div>
                {user?.subscriptionStatus !== 'Paid' &&
                <OfferSmall/>}
            </div>
        );
    }
;

export default IntroGuide;