import React from 'react';
import cl from "./ProjectMenuContainer.module.css";
import NumberOfOnlinePeople from "../../../../entities/NumberOfOnlinePeople/NumberOfOnlinePeople";
import ProjectMenu from "../../../../features/ProjectMenu/ProjectMenu";

const ProjectMenuContainer = ({projectListDetails}) => {
    const styles = {
        activeProjectMenuItem: cl.activeProjectMenuItem,
        availableProjectMenuItem: cl.availableProjectMenuItem,
        restrictedProjectMenuItem:cl.restrictedProjectMenuItem
    }

    return (
        <div className={cl.wrap}>
            <h2 className={cl.title}>Projects</h2>
            <div className={cl.onlinePeopleWrap}>
                <NumberOfOnlinePeople/>
            </div>
            <ProjectMenu projectList={projectListDetails} styles={styles}/>
        </div>
    );
};

export default ProjectMenuContainer;