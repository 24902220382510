const defaultIsAuthState = {
    isAuth: false,
}
const defaultUserSessionDataState = {
    userSessionData: {}
}

const ActionTypes = {
    SET_IS_AUTH: "SET_IS_AUTH",
    SET_USER_SESSION_DATA:"SET_USER_SESSION_DATA",
    SET_USER_DETAILS:"SET_USER_DETAILS",
    SET_USER_ACCESS_TOKEN:"SET_USER_ACCESS_TOKEN",
};

export const isAuth = (state = defaultIsAuthState, action) => {
    switch (action.type) {
        case ActionTypes.SET_IS_AUTH:
            return {...state, isAuth: action.payload}
        default:
            return state;
    }
}

export const userSessionData = (state = defaultUserSessionDataState, action) => {
    switch (action.type) {
        case ActionTypes.SET_USER_SESSION_DATA:
            return {...state, userSessionData: action.payload}
        case ActionTypes.SET_USER_DETAILS:
            return { ...state, userSessionData: { ...state.userSessionData, userDetails: action.payload } };
        case ActionTypes.SET_USER_ACCESS_TOKEN:
            return { ...state, userSessionData: { ...state.userSessionData, accessToken: action.payload } };
        default:
            return state;
    }
}


export const setIsAuth = (payload) => ({type: ActionTypes.SET_IS_AUTH, payload})
export const setUserSessionData = (payload) => ({type: ActionTypes.SET_USER_SESSION_DATA, payload})
export const setUserDetails = (payload) => ({type: ActionTypes.SET_USER_DETAILS, payload})
export const setUserAccessToken = (payload) => ({type: ActionTypes.SET_USER_ACCESS_TOKEN, payload})