import React from 'react';
import Faq from "react-faq-component";
import './CustomFaq.css'
const CustomFaq = ({data}) => {
    const config = {
        animate: true,
        arrowIcon: "v",
        tabFocus: true
    };
    return (<div className="customFaqWrap">
            <Faq data={data}
                 styles={{}}
                 config={config}/>
        </div>

    );
};

export default CustomFaq;