const defaultState = {
    currentPage: '',
}

const ActionTypes = {
    SET_CURRENT_PAGE: "SET_CURRENT_PAGE",
};

export const currentPage = (state = defaultState, action) => {
    switch (action.type) {
        case ActionTypes.SET_CURRENT_PAGE:
            return {...state, currentPage: action.payload}
        default:
            return state;
    }
}


export const setCurrentPage = (payload) => ({type: ActionTypes.SET_CURRENT_PAGE, payload})