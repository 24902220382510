import React, {useEffect, useState} from 'react';
import {UserApi} from "../../shared/api/userApi";
import cl from "./AutoSavedUserInput.module.css";
import SavedIcon from "../../shared/UI/SavedIcon/SavedIcon";
import {useDebouncedSaveToApi} from "../../shared/utils/hooks/useDebouncedSaveToApi";
import {useDispatch, useSelector} from "react-redux";
import {setUserDetails} from "../../shared/store/userSessionDataReducer";

const AutoSavedUserInput = ({userPropertyName, apiMethod}) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.userSessionData.userSessionData.userDetails);

    const [propertyValue, setPropertyValue] = useState(user ? user[userPropertyName] : 0);
    const [showSavedIcon, debouncedProperty] = useDebouncedSaveToApi(apiMethod, propertyValue, 500, 500)


    useEffect(() => {
        dispatch(setUserDetails({...user, [userPropertyName]: propertyValue}));
    }, [debouncedProperty])

    return (
        <div className={cl.wrap}>
            <input placeholder={propertyValue} name={userPropertyName}
                   type="text" className={cl.property}
                   value={propertyValue} onChange={(e) => setPropertyValue(e.target.value)}/>
            <SavedIcon showSavedIcon={showSavedIcon}>Saved</SavedIcon>
        </div>
    );
};

export default AutoSavedUserInput;