import React, {useState} from 'react';
import cl from './JsTab.module.css'
import TitleWithComment from "../../../../../shared/UI/TitleWithComment/TitleWithComment";
import Code from "../../../../../entities/Code/Code";
import Button_lilac from "../../../../../shared/UI/Button_lilac/Button_lilac";
import SavedIcon from "../../../../../shared/UI/SavedIcon/SavedIcon";

const JsTab = ({code}) => {
    const [showSavedIcon, setShowSavedIcon] = useState(false)

    async function handleCopyTheCode() {
        await navigator.clipboard.writeText(code);
        if (!showSavedIcon) {
            setShowSavedIcon(true)
            setTimeout(() => setShowSavedIcon(false), 500)
        }
    }

    return (
        <div className={cl.wrap}>
            <div className={cl.jsHoverHintTitleWrap}>

                <TitleWithComment
                    data={{
                        title: code ? 'Java Script code' : 'No java script required',
                        comment: code ?
                            <div>
                                <div style={{marginBottom: '20px'}}>Hover the command to see the details</div>
                                <Button_lilac onClick={handleCopyTheCode}>Copy the code</Button_lilac>
                                <SavedIcon showSavedIcon={showSavedIcon}>Copied</SavedIcon>
                            </div> : 'But it\'s still useful project to learn',
                        leftHeight: '18vh'
                    }}/>
            </div>
            {code &&
            <Code rawCode={code}/>
            }

        </div>
    );
};

export default JsTab;