import {combineReducers, createStore} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import {currentPage} from "./currentPageReducer";
import {isAuth, userSessionData} from "./userSessionDataReducer";
import {pageIsLoading} from "./pageIsLoading";

const rootReducer = combineReducers({
    currentPage:currentPage,
    userSessionData:userSessionData,
    isAuth:isAuth,
    pageIsLoading:pageIsLoading,
})
export const store = createStore(rootReducer, composeWithDevTools());