import React from "react";
import Home from "../../pages/Home/Home"
import NotFound from "../../pages/NotFound/NotFound"
import Login from "../../pages/Login/Login";
import Signup from "../../pages/Signup/Signup";
import UserProfile from "../../pages/UserProfile/UserProfile";
import OfferPayment from "../../pages/OfferPayment/OfferPayment";
import HtmlGuide from "../../pages/Guides/HtmlGuide/HtmlGuide";
import CssGuide from "../../pages/Guides/CssGuide/CssGuide";
import JsGuide from "../../pages/Guides/JsGuide/JsGuide";
import IntroGuide from "../../pages/Guides/IntroGuide/IntroGuide";
import PrivacyPolicy from "../../pages/PrivacyPolicy/PrivacyPolicy";
import TermsOfUse from "../../pages/TermsOfUse/TermsOfUse";
import Contacts from "../../pages/Contacts/Contacts";


export const publicRoutes = [
    {path: '/', component: Home, exact: true},
    {path: '/not-found', component: NotFound, exact: true},
    {path: '/login', component: Login, exact: true},
    {path: '/signup', component: Signup, exact: true},

    {path: '/html-guide', component: HtmlGuide, exact: true},
    {path: '/css-guide', component: CssGuide, exact: true},
    {path: '/js-guide', component: JsGuide, exact: true},
    {path: '/privacy-policy', component:PrivacyPolicy , exact: true},
    {path: '/terms-of-use', component:TermsOfUse , exact: true},
    {path: '/contacts', component:Contacts , exact: true},
]

export const authRoutes = [
    {path: '/me', component: UserProfile, exact: true},
    {path: '/offer', component: OfferPayment, exact: true},
]

export const paidRoutes = [
    {path: '/introduction', component: IntroGuide, exact: true},
]
