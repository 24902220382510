import React, {forwardRef, useEffect, useMemo, useRef, useState} from 'react';
import cl from './Command.module.css'
import {CommandApi} from "../../../shared/api/commandApi";
import {useHover} from "../../../shared/utils/hooks/useHover";
import {codePalette} from "../../../shared/styles/styles";
import Hint from "../Hint/Hint";
import {createPortal} from 'react-dom';

const Command = ({commandName, typeData}) => {
    const [commandData, setCommandData] = useState({});

    const [commandRef, isHoveredDefault] = useHover();
    const [isHovered, setIsHovered] = useState();

    const [domReady, setDomReady] = React.useState(false)
    const [hintTop, setHintTop] = useState(null)
    const [hintLeft, setHintLeft] = useState(null)

    useEffect(() => {
        setIsHovered(isHoveredDefault);
    }, [isHoveredDefault])

    React.useEffect(() => {
        setDomReady(true)

        if (commandRef.current) {
            setHintPosition()
            regulateHoverWhenScrolled()
        }
    }, [])

    function setHintPosition() {
        const codeFrame = document.getElementById('jsCodeFrame');
        const codeFrameRect = codeFrame.getBoundingClientRect();
        const commandRect = commandRef?.current?.getBoundingClientRect();
        setHintTop(commandRef.current.offsetTop + commandRef.current.offsetHeight + 5)
        setHintLeft(commandRect.left - codeFrameRect.left + codeFrame.scrollLeft)
    }

    function regulateHoverWhenScrolled() {
        let scrollStart = false;

        document.getElementById('jsCodeWrap').addEventListener('scroll', () => {
            if (!scrollStart) {
                setIsHovered(false)
            }
            scrollStart = true;

        })
        document.getElementById('jsCodeWrap').addEventListener('scrollend', () => {
            scrollStart = false;
            setIsHovered(isHoveredDefault)
        })
    }


    useEffect(() => {
        defineCommandData()
    }, [])

    function defineCommandData() {
        if (typeData.name === 'specific') {
            const cachedData = localStorage.getItem(commandName);
            if (cachedData) {
                setCommandData(JSON.parse(cachedData));
            } else {
                fetchAndSetCommandInfo(commandName)
            }
        } else {
            setCommandData(typeData)
        }
    }

    async function fetchAndSetCommandInfo(name) {
        try {
            const response = await CommandApi.getCommandByName(name);

            setCommandData(response);
            localStorage.setItem(name, JSON.stringify(response));
        } catch (e) {
            setCommandData({hint: 'error', color: '#f00'})
        }
    }

    return (
        <span ref={commandRef} className={cl.wrap + (isHovered ? ' ' + cl.hovered : '')}
              style={{color: codePalette[commandData.color]}} >
            {domReady &&
            createPortal(<Hint name={typeData.name === 'specific' ? commandData.name : ''} isHovered={isHovered}
                               style={{
                                   left: `${hintLeft}px`,
                                   top: `${hintTop}px`
                               }}>{commandData.hint}</Hint>, document.getElementById('jsCodeFrame'))}
            {commandName}
        </span>);
};

export default Command;