import {$authHost, $host} from "./index";
import {setIsAuth, setUserDetails, setUserSessionData} from "../store/userSessionDataReducer";
import {useDispatch} from "react-redux";
import React from 'react';

export const UserApi = {
    async registration(email, password) {
        const timezoneOffset = Math.round(new Date().getTimezoneOffset()/60)*-1
        const {data} = await $host.post('signup', {email, password, timezoneOffset})
        localStorage.setItem('accessToken', data.accessToken)
        return data
    },

    async useLogin() {
        const dispatch = useDispatch();
        const login = async (email, password) => {
            let loginResponse = await $host.post('login', {email, password});
            localStorage.setItem('accessToken', loginResponse.data.accessToken);
            let getUserResponse = await $authHost.get('getUser')
            dispatch(setIsAuth(true));
            dispatch(setUserDetails(getUserResponse.data));
        };
        return login;
    },

    async useUpdateTokens() {
        const dispatch = useDispatch();
        const updateTokens = async () => {
            const {data} = await $authHost.post('updateTokens')
            dispatch(setUserSessionData(data));
            localStorage.setItem('accessToken', data.tokens.accessToken)
        }
    },

    async getUser() {
        const {data} = await $authHost.get('getUser')
        return data
    },

    async logout() {
        const {data} = $host.post('logout',)
        localStorage.removeItem('accessToken')
        return data
    },

    async resendActivationLink(email, password) {
        const {data} = await $host.post('resendActivationLink', {email, password})
        return data
    },

    async updateFullName(newFullName) {
        const {data} = await $authHost.post(`updateFullName`, {newFullName})
        return data
    },

    async changePassword(oldPassword, newPassword) {
        const {data} = await $authHost.post(`changePassword`, {oldPassword, newPassword})
        return data;
    },

    async sendNewPassword(email) {
        //TODO probably don't need authHost here. Need tests
        const {data} = await $host.post(`sendNewPassword`, {email})
        return data;
    },

    async getOnlineUsers() {
        const {data} = await $host.get(`getOnlineUsers`)
        return data;
    }

}

