import cl from './UserDetails.module.css'

import React, {useState} from "react";
import {UserApi} from "../../shared/api/userApi";
import TitleWithComment from "../../shared/UI/TitleWithComment/TitleWithComment";
import LogOut from "../../features/LogOut/LogOut";
import EmailIcon from "../../shared/UI/icons/EmailIcon";
import CardIcon from "../../shared/UI/icons/CardIcon";
import UserIdIcon from "../../shared/UI/icons/UserIdIcon";
import Button_lilac from "../../shared/UI/Button_lilac/Button_lilac";
import PackIcon from "../../shared/UI/icons/PackIcon";
import PaypalPopup from "../PaypalPopup/PaypalPopup";
import RecoverConfirmPopup from "../popups/RecoverConfirmPopup/RecoverConfirmPopup";
import ChangePasswordPopup from "../popups/ChangePasswordPopup/ChangePasswordPopup";
import Button_lilac_outline from "../../shared/UI/Button_lilac_outline/Button_lilac_outline";
import AutoSavedUserInput from "../../entities/AutoSavedUserInput/AutoSavedUserInput";

const UserDetails = ({user}) => {
    const [showPaymentGate, setShowPaymentGate] = useState(false)
    const [recoverConfirmPopupIsVisible, setRecoverConfirmPopupIsVisible] = useState(false)
    const [changePasswordPopupIsVisible, setChangePasswordPopupIsVisible] = useState(false)

    return (
        <div className={cl.wrap}>
            <div className={cl.infoWrap}>
                <TitleWithComment data={{
                    leftHeight: '100px',
                    title: 'My profile',
                    comment: 'Account Details'
                }}/>
                <ul className={cl.infoList}>
                    <li>
                        <div className={cl.itemName}><UserIdIcon/>Full name:</div>
                        <AutoSavedUserInput userPropertyName={'fullName'} apiMethod={UserApi.updateFullName}/>
                    </li>
                    <li>
                        <div className={cl.itemName}><EmailIcon/>Email:</div>
                        <span>  {user.email}</span>
                    </li>
                    <li>
                        <div className={cl.itemName}><CardIcon/>Subscription status:</div>
                        <span> {user.subscriptionStatus}</span>
                    </li>
                    {user.subscriptionStatus !== 'free' &&
                    <li>
                        <div className={cl.itemName}>End of the subscription:</div>
                        <span> {user?.endOfSubscription?.split('T')[0]}</span>
                    </li>}
                    {user.subscriptionStatus !== 'free' &&
                    <li className={cl.renewButtonWrap}>
                        <Button_lilac onClick={() => setShowPaymentGate(!showPaymentGate)}>Renew
                            subscription <PackIcon/>
                        </Button_lilac>
                    </li>}
                    <li className={cl.strikeWrap} style={{marginTop:'30px'}}>
                        <div className={cl.itemName}>Your timezone:</div>
                        <span>{user.timezoneOffset>=0&&'+' + user.timezoneOffset}</span>
                    </li>
                    <li className={cl.strikeWrap}>
                        <div className={cl.itemName}>Your strike:</div>
                        <span>{user.strike}</span>
                    </li>
                </ul>
                <PaypalPopup showPaymentGate={showPaymentGate} setShowPaymentGate={setShowPaymentGate}/>
                <div className={cl.passwordHelp}>
                    <Button_lilac_outline  onClick={() => setRecoverConfirmPopupIsVisible(true)}>
                        Recover password
                    </Button_lilac_outline>
                    {recoverConfirmPopupIsVisible && <RecoverConfirmPopup email={user.email} setPopupIsVisible={setRecoverConfirmPopupIsVisible}/>}

                    <Button_lilac_outline onClick={() => setChangePasswordPopupIsVisible(true)}>
                        Change password
                    </Button_lilac_outline>
                    {changePasswordPopupIsVisible && <ChangePasswordPopup email={user.email} setPopupIsVisible={setChangePasswordPopupIsVisible}/>}
                </div>
                <LogOut><Button_lilac>Log out</Button_lilac></LogOut>
            </div>
        </div>
    );
};

export default UserDetails;