import React from 'react';
import {AnimatePresence, motion} from "framer-motion";
import cl from "./TabContainer.module.css";

const TabContainer = ({tabMenuData, currentTab}) => {
    return (
        <AnimatePresence mode='wait'>
            {tabMenuData.map((tab) =>
                currentTab === tab.id && (
                    <motion.div key={tab.id} initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}
                                transition={{duration: 0.2}} className={cl.tabWrap}>
                        {tab.component}
                    </motion.div>))}
        </AnimatePresence>
    );
};

export default TabContainer;