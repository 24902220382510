import React from 'react';
import cl from './Button_lilac_outline.module.css'

const ButtonLilacOutline = ({children, className, ...props}) => {
    return (
        <button {...props} className={`${cl.button} ${className}`}>
            {children}
        </button>
    );
};

export default ButtonLilacOutline;