import React, {useEffect, useState} from 'react';
import TabMenuContainer from "../TabMenuContainer/TabMenuContainer";
import TabContainer from "../TabContainer/TabContainer";
import {useMediaQuery} from "../../../../shared/utils/hooks/useMediaQuery";
import useTrackScrollY from "../../../../shared/utils/hooks/useTrackScrollY";
import cl from './ProjectWrap.module.css'
const ProjectWrap = ({tabMenuDataCode, tabMenuDataGuide, currentTabGuide,
                     currentTabCode, setCurrentTabCode, setCurrentTabGuide}) => {
    const isMobileVersion = useMediaQuery('(max-width: 992px)');
    const relRef = React.useRef(0)

    const [refVisible, setRefVisible] = useState(false)

    useEffect(() => {
        if (!refVisible) {
            return
        }
        // detected rendering
    }, [refVisible])
    const scrollY = useTrackScrollY()
    return (
        <div className={cl.wrap}>
            {isMobileVersion &&
                <div>
                    <TabMenuContainer tabMenuData={tabMenuDataGuide.concat(tabMenuDataCode)}
                                      currentTab={currentTabGuide}
                                      changeCurrentTab={setCurrentTabGuide}/>
                    <TabContainer tabMenuData={tabMenuDataGuide.concat(tabMenuDataCode)}
                                  currentTab={currentTabGuide}/>
                </div>}
            {!isMobileVersion &&
                <div>
                    <div>
                        <TabMenuContainer tabMenuData={tabMenuDataGuide} currentTab={currentTabGuide}
                                          changeCurrentTab={setCurrentTabGuide}/>
                        <TabContainer tabMenuData={tabMenuDataGuide} currentTab={currentTabGuide}/>
                    </div>

                </div>}
            {!isMobileVersion &&
                <div style={{position: 'relative'}} ref={el => {
                    relRef.current = el;
                    setRefVisible(!!el);
                }}>
                    <div style={scrollY - 140 < -50 ?
                        {
                            position: 'fixed',
                            top: 140 - scrollY,
                            left: relRef.current.offsetLeft,
                            right: 0,
                            overflowY: 'scroll',
                            height: 'calc(100vh - 50px)',
                            borderLeft: "2px dashed var(--grey)",
                            zIndex: '2'
                        } : {
                            position: 'fixed',
                            top: '50px',
                            left: relRef.current.offsetLeft,
                            right: 0,
                            overflowY: 'scroll',
                            height: 'calc(100vh - 50px)',
                            borderLeft: "2px dashed var(--grey)",
                            zIndex: '2'
                        }}>
                        <TabMenuContainer tabMenuData={tabMenuDataCode} currentTab={currentTabCode}
                                          changeCurrentTab={setCurrentTabCode}/>
                        <TabContainer tabMenuData={tabMenuDataCode} currentTab={currentTabCode}/>
                    </div>
                </div>
            }
        </div>
    );
};

export default ProjectWrap;