import React from 'react';
import ProjectTabMenu from "../../modules/ProjectTabMenu/ProjectTabMenu";
import cl from './TabMenuContainer.module.css'

const TabMenuContainer = ({tabMenuData, currentTab, changeCurrentTab}) => {
    return (
        <div className={cl.wrap}>
            <ProjectTabMenu tabMenuData={tabMenuData} currentTab={currentTab}
                            changeCurrentTab={changeCurrentTab} activeClass={cl.active}/>
        </div>
    );
};

export default TabMenuContainer;