import React, {useEffect, useState} from 'react';
import cl from './Header.module.css'
import Logo from "../../shared/UI/Logo/Logo";
import NavMenu from "../../entities/NavMenu/NavMenu";
import {Link, useLocation} from "react-router-dom";
import Hamburger from "./Hamburger/Hamburger";
import {navMenuItems} from "./constant/navMenuItems";
const Header = () => {
    const location = useLocation()
        const [hamIsActive, setHamIsActive] = useState(false);
        useEffect(()=>{
            setHamIsActive(false)
        },[location])

        return (
            <header className={cl.header}>
                <div className={cl.logoWrap}>
                    <Link to={'/'}><Logo/></Link>
                </div>
                <div className={cl.menuWrap}>
                    <NavMenu menuItems={navMenuItems} hamIsActive={hamIsActive}/>
                </div>
                <Hamburger hamIsActive={hamIsActive} setHamIsActive={setHamIsActive}/>
            </header>
        );
    }
;

export default Header;