import React, {useState} from 'react';
import cl from './Project.module.css'
import {useMediaQuery} from "../../shared/utils/hooks/useMediaQuery";
import {Link} from "react-router-dom";
import LeftArrow from "../../shared/UI/icons/LeftArrow";
import ProjectWrap from "./ui/ProjectWrap/ProjectWrap";

const Project = ({projectData}) => {
    console.log(projectData.tabList())
    const tabMenuDataGuide = projectData.tabList().filter((tab)=>{tab.tabType='guide'});

    const tabMenuDataCode = projectData.tabList().filter((tab)=>{tab.tabType='code'});


    const isMobileVersion = useMediaQuery('(max-width: 992px)');

    const [currentTabGuide, setCurrentTabGuide] = useState('try-project')
    const [currentTabCode, setCurrentTabCode] = useState('icon')

    return (<div className={cl.wrap}>
            {isMobileVersion && <Link to={'/pet-projects'} className={cl.returnButton}><LeftArrow/> Project list</Link>}
            <ProjectWrap tabMenuDataCode={tabMenuDataCode} tabMenuDataGuide={tabMenuDataGuide}
                         currentTabGuide={currentTabGuide}
                         currentTabCode={currentTabCode} setCurrentTabCode={setCurrentTabCode}
                         setCurrentTabGuide={setCurrentTabGuide}/>

        </div>
    );
};

export default Project;