import React, {useEffect} from 'react';
import cl from "./RecoverPopup.module.css";
import EmailIcon from "../../../shared/UI/icons/EmailIcon";

const RecoverPopup = ({setPopupIsVisible}) => {

    return (
        <div className={cl.wrap} onClick={()=>setPopupIsVisible(false)}>
            <div className={cl.popupWrap} onClick={(e)=>e.stopPropagation()}>
                <button className={cl.exitButton} onClick={()=>setPopupIsVisible(false)}>x</button>
                <div className={cl.titleWrap}>
                    <h3 className={cl.title}>Check Your Email </h3>
                    <span className={cl.emailIcon}><EmailIcon/></span>
                </div>
                <h6 className={cl.text}>We have sent you a new password on your email </h6>
            </div>
        </div>
    );
};

export default RecoverPopup;