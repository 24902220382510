import React, {useEffect, useState} from 'react';
import cl from "../Signup/Signup.module.css";
import {Link, useNavigate} from "react-router-dom";
import Button_lilac from "../../shared/UI/Button_lilac/Button_lilac";
import LoginExternalWay from "../../entities/LoginExternalWay/LoginExternalWay";
import {UserApi} from "../../shared/api/userApi";
import Error from "../../shared/UI/Error/Error";
import useAuthorization from "../../shared/utils/hooks/useCollectErrors";
import EmailConfirmPopup from "../../widgets/popups/EmailConfirmPopup/EmailConfirmPopup";
import useCollectErrors from "../../shared/utils/hooks/useCollectErrors";
import PasswordInput from "../../entities/PasswordInput/PasswordInput";


const Signup = () => {
    const [popupIsVisible, setPopupIsVisible] = useState(false)
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [signUpWithCollectedErrors, errors] = useCollectErrors(() => UserApi.registration(email, password));

    const handleRegistrationSubmit = async (e) => {
        e.preventDefault();
        const response = await signUpWithCollectedErrors()
        if (response) setPopupIsVisible(true)
    }
    return (
        <div className={cl.wrap}>
            {popupIsVisible && <EmailConfirmPopup email={email} password={password} setPopupIsVisible={setPopupIsVisible}/>}
            <form action="" className={cl.form}>
                <h1 className={cl.title}>
                    Get started for free
                </h1>
                <div className={cl.inputsWrap}>
                    <input type="text" placeholder='Email' value={email} onChange={e => setEmail((e.target.value))}/>
                    <Error errors={errors} type="email"/>
                   <PasswordInput setPassword={setPassword} errors={errors}/>
                </div>
                <h6 className={cl.licenseAgreement}>By signing up, you agree to our <Link
                    to={'/terms-of-use'} target="_blank">Terms of Use</Link> and <Link
                    to={'/privacy-policy'} target="_blank">Privacy Policy</Link></h6>
                <div className={cl.registrationButtonWrap}>
                    <Button_lilac type="submit" style={{ width: '100%'}}
                                  onClick={(e) => handleRegistrationSubmit(e)}>Sign up</Button_lilac>
                </div>
                {/*TODO <LoginExternalWay/>*/}
                <p className={cl.loginLinkWrap}>
                    Already have an account? <Link to={'/login'} className={cl.loginLink}>Log in</Link>
                </p>
            </form>
        </div>
    );
};

export default Signup;