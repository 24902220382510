import React from 'react';
import TitleWithComment from "../../../../../shared/UI/TitleWithComment/TitleWithComment";
import cl from './GuideTab.module.css'
import TitleNumbered from "../../../../../shared/UI/TitleNumbered/TitleNumbered";

const GuideTab = ({data}) => {
    return (
        <div className={cl.wrap}>
            <div className={cl.titleWithCommentWrap}>
                <TitleWithComment
                    data={{leftHeight: '11vh', title: data.title, comment: 'Essential steps for project'}}/>
            </div>

            <div className={cl.titleNumberedWrap}>
                <TitleNumbered title={'Creating an HTML structure'} number={1}/>
            </div>
            <p>
                {data.htmlParagraph}
            </p>
            <div className={cl.titleNumberedWrap}>
                <TitleNumbered title={'Setting styles '} number={2}/>
            </div>
            <p>
                {data.cssParagraph}
            </p>
            {
                data?.jsParagraph&&<div>
                    <div className={cl.titleNumberedWrap}>
                        <TitleNumbered title={'Let\'s add some interaction'} number={3}/>
                    </div>
                    <p>
                        {data.jsParagraph}
                    </p>
                </div>

            }

            <p>
                {data.lastComment}
            </p>


        </div>
    );
};

export default GuideTab;