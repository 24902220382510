import {$host} from "./index";

export const CommandApi = {
    async getCommandByName(commandName) {
        const {data} = await $host.get(`getCommand/${commandName}`)
        return data
    },
    async getAllCommandTypes() {
        try{
            const {data} = await $host.get(`getAllCommandTypes`)
            return data
        }catch(e){
            console.log(0)
        }

    }
}