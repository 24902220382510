import React from 'react';
import cl from './Benefits.module.css'
import Sticks from "../../../../shared/UI/Sticks/Sticks";
import TitleWithComment from "../../../../shared/UI/TitleWithComment/TitleWithComment";

const Benefits = () => {
    return (
        <div className={cl.wrap} id="benefits">
            <h2 className={cl.title+' semiBold'}>Why should I start with projects?</h2>
            <div className={cl.listWrap}>
                <ul className={cl.list}>
                    <li>
                        <TitleWithComment data={{title:'Hands-on experience', comment:'Projects allow you to apply the concepts you learn immediately'}}/>
                    </li>
                    <li>
                        <TitleWithComment data={{title:'Engagement', comment:'Working on projects provides a real-world context for learning programming'}}/>
                    </li>
                    <li>
                        <TitleWithComment data={{title:'Problem-Solving Skills', comment:'Programming is all about problem-solving'}}/>
                    </li>
                    <li>
                        <TitleWithComment data={{title:'Contextual Learning', comment:'When you work on projects, you often need to use multiple programming concepts and techniques together'}}/>
                    </li>
                    <li>
                        <TitleWithComment data={{title:'Practical Knowledge', comment:'Projects give you the opportunity to learn practical skills that are directly applicable in the real world'}}/>
                    </li>
                    <li>
                        <TitleWithComment data={{title:'Learn from Mistakes', comment:'While working on projects, you\'re likely to encounter errors and face challenges along the way'}}/>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Benefits;