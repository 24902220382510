import React from 'react';
import CodeIcon from "../../../../shared/UI/icons/CodeIcon";
import {darkPrimaryColor} from "../../../../shared/styles/styles";
import cl from './DefaultCodeTab.module.css'
import TitleWithComment from "../../../../shared/UI/TitleWithComment/TitleWithComment";
import CodeIcon2 from "../../../../shared/UI/icons/CodeIcon2";
import CodeIcon3 from "../../../../shared/UI/icons/CodeIcon3";

const DefaultCodeTab = () => {
    return (
        <div className={cl.wrap}>
            <div className={cl.titleWithCommentWrap}>
                <h3 className={cl.title}>
                    All code for project is here
                </h3>
                <h5>
                    Choose a tab above to browse more
                </h5>
            </div>
            <div className={cl.codeIconWrap}>
                <CodeIcon3 color={darkPrimaryColor}/>
            </div>
        </div>
    );
};

export default DefaultCodeTab;