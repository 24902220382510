import React, {useEffect, useState} from 'react';
import {PayPalScriptProvider, PayPalButtons} from '@paypal/react-paypal-js';
import cl from './PaypalGate.module.css'
import {UserApi} from "../../../shared/api/userApi";
import {PaypalApi} from "../../../shared/api/paypalApi";
import {setIsAuth, setUserSessionData} from "../../../shared/store/userSessionDataReducer";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from 'react-router-dom';

const PaypalGate = ({setShowPaymentGate, totalPrice}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const initialOptions = {
        'client-id': 'AQBqpCvicUJ1po3DKW7QNjJxPYzVdeWrT6aMVXwcv7KRwN9V4W99P1MK8J6OMAJ64DFQrLLoWHv1APPY',
        currency: 'USD',
        intent: 'capture',
    };

    const handleCreateOrder = async (data) => {
        // Order is created on the server and the order id is returned
        const res = await PaypalApi.createOrder(totalPrice);
        return res.id;
    };

    const handleOnApprove = async (data) => {
        // Order is captured on the server and the response is returned to the browser
        const res = await PaypalApi.captureOrder(data)
        const fetchedUser = await UserApi.getUser()
        dispatch(setUserSessionData(fetchedUser));
        setShowPaymentGate(false);
        navigate('/introduction', {replace: true});


        return res
    };
    return (
        <div className={cl.wrap}>
            <PayPalScriptProvider options={initialOptions}>
                <PayPalButtons
                    forceReRender={[totalPrice]}
                    createOrder={(data, actions) => handleCreateOrder(data, actions)}
                    onApprove={(data, actions) => handleOnApprove(data, actions)}
                    style={{color: "gold"}}
                />
            </PayPalScriptProvider>
        </div>
    );
}

export default PaypalGate;