import React from 'react';
import cl from '../Guide.module.css'
import localCl from './CssGuide.module.css'
import TitleWithComment from "../../../shared/UI/TitleWithComment/TitleWithComment";
import TitleNumbered from "../../../shared/UI/TitleNumbered/TitleNumbered";
import OfferSmall from "../../../widgets/OfferSmall/OfferSmall";
import SyntaxHighlighter from "react-syntax-highlighter";
import {vs2015} from "react-syntax-highlighter/dist/cjs/styles/hljs";
import {useSelector} from "react-redux";
import boxModel from './assets/boxModel.png'

const CssGuide = ({}) => {
        const user = useSelector(state => state.userSessionData.userSessionData.userDetails);
        return (
            <div className={cl.wrap + ' ' + localCl.wrap} style={user?.subscriptionStatus !== 'Paid'?{maxHeight: '1447px'}:{}}>
                <div className={cl.courseWrap}>
                    <div className={cl.titleWrap}>
                        <TitleWithComment
                            data={{title: 'CSS', comment: 'Essential CSS details for all projects'}}/>
                    </div>
                    <div className={cl.paragraphsWrap}>
                        <div className={cl.paragraphWrap}>
                            <div className={cl.courseTitleWrap}>
                                <TitleNumbered title={'What is CSS?'} number={1}/>
                            </div>
                            <p className={cl.courseTextWrap}>
                                CSS, or <b>Cascading Style Sheets</b>, is what you'll use to give your HTML web content its
                                unique
                                <b> style</b> and <b>appearance</b>. While HTML structures your webpage like a
                                building's <b>framework</b>, CSS
                                acts as the interior <b>designer</b>, allowing you to change colors, fonts, spacing, and
                                layouts to
                                make your site visually appealing and user-friendly. <br/><br/>It's the key to
                                creating <b>attractive</b> and
                                <b> professionally</b> designed web pages. Whether you want bigger headings, custom button
                                styles,
                                or responsive layouts, CSS is the tool to make it happen as you progress in web
                                development</p>
                            <p className={cl.courseTextWrap}>
                                Maily, a CSS consists of a <b>selector</b> and a <b>declaration block</b> enclosed in curly
                                braces.
                                For example, <b>h1 &#123; color: blue; &#125;</b> is a CSS rule that selects
                                all <b>&lt;h1&gt;</b> elements and sets their <b>text color</b> to <b>blue</b>
                            </p>

                        </div>
                        <div className={cl.paragraphWrap}>
                            <div className={cl.courseTitleWrap}>
                                <TitleNumbered title={'How to apply CSS rules to HTML?'} number={2}/>
                            </div>
                            <p className={cl.courseTextWrap}>
                                You can include CSS rules <b>directly within</b> your HTML document by placing them
                                between <b>&lt;style&gt;</b> tags. These tags should be placed within
                                the <b>&lt;head&gt;</b> section of
                                your HTML document
                            </p>
                            <SyntaxHighlighter wrapLongLines={false} language="htmlbars" style={vs2015}>
                                {'<!DOCTYPE html>\n' +
                                '<html>\n' +
                                '<head>\n' +
                                '    <title>Embedded CSS Example</title>\n' +
                                '    <style>\n' +
                                '        /* CSS rules go here */\n' +
                                '        body {\n' +
                                '            font-family: Arial, sans-serif;\n' +
                                '            background-color: #f0f0f0;\n' +
                                '        }\n' +
                                '        h1 {\n' +
                                '            color: #111;\n' +
                                '        }\n' +
                                '        /* More CSS rules... */\n' +
                                '    </style>\n' +
                                '</head>\n' +
                                '<body>\n' +
                                '    <h1>Welcome to My Website</h1>\n' +
                                '    <p>This is an example of embedded CSS.</p>\n' +
                                '</body>\n' +
                                '</html>'}
                            </SyntaxHighlighter>
                            <p className={cl.courseTextWrap}>
                                However, for larger projects and for the sake of maintainability, it's often recommended to
                                use an <b>external CSS file</b> and link it to your HTML document. To connect an external
                                CSS file
                                to an HTML document, use the <b>&lt;link&gt;</b> element within
                                the <b>&lt;head&gt;</b> section of your
                                HTML file. Set the <b>href</b> attribute of the <b>&lt;link&gt;</b> element to specify
                                the <b>path </b>to your external CSS
                                file, like
                                this:
                            </p>
                            <SyntaxHighlighter wrapLongLines={false} language="htmlbars" style={vs2015}>
                                {'<!DOCTYPE html>\n' +
                                '<html>\n' +
                                '<head>\n' +
                                '    <title>External CSS Example</title>\n' +
                                '    <link rel="stylesheet" type="text/css" href="styles.css">\n' +
                                '</head>\n' +
                                '<body>\n' +
                                '    <h1>Welcome to My Website</h1>\n' +
                                '    <p>This is an example of using an external CSS file.</p>\n' +
                                '</body>\n' +
                                '</html>\n'}
                            </SyntaxHighlighter>

                        </div>
                        {user?.subscriptionStatus === 'Paid' && <div>
                            <div className={cl.paragraphWrap}>
                                <div className={cl.courseTitleWrap}>
                                    <TitleNumbered title={'Selectors and elements'} number={3}/>
                                </div>
                                <p className={cl.courseTextWrap}>
                                    <b>Selectors</b> and <b>elements</b> are fundamental concepts in web development and CSS.
                                    Think of
                                    selectors as "pointers" or "targets" that you use to identify and style <b>specific
                                    parts</b> of
                                    your HTML content. Elements, on the other hand, are
                                    the <b>individual</b> building <b>blocks</b> of your
                                    HTML document, such as headings, paragraphs, divs, links, images, and more. Selectors help
                                    you
                                    pick out these elements to apply <b>styling rules.</b> Selectors go <b>before</b> block
                                    enclosed in curly braces<br/><br/>
                                    Now, let's talk about the <b>ID attribute</b> in HTML and the difference
                                    between <b>IDs</b> and <b>classes</b>:
                                </p>
                                <SyntaxHighlighter wrapLongLines={false} language="htmlbars" style={vs2015}>
                                    {'<p class="human highlight" id="steve">\n' +
                                    '       Here could be any text\n' +
                                    '</p>'}
                                </SyntaxHighlighter>
                                <p className={cl.courseTextWrap}>
                                    The primary <b>difference</b> between IDs and classes is that IDs must be <b>unique</b>,
                                    while classes can
                                    be applied to <b>multiple</b> elements. If you have several elements that should share <b>the
                                    same styling</b>,
                                    you'd use a <b>class</b>. For example, you could have multiple paragraphs with the
                                    class &lt;p class="highlight"&gt;, and a CSS rule targeting <b>.highlight</b> would style
                                    all of them the
                                    same way. On the other hand, if you have an element that needs
                                    a <b>specific, one-time</b> styling,
                                    you'd use an ID
                                </p>
                                <SyntaxHighlighter wrapLongLines={false} language="htmlbars" style={vs2015}>
                                    {'<p class="human highlight" id="steve">\n' +
                                    '       Here could be any text\n' +
                                    '</p>'}
                                </SyntaxHighlighter>
                                <p className={cl.courseTextWrap + ' ' + cl.mt}>
                                    Now let's get back to the topic. There are
                                    several types of selectors to choose from:<br/><br/>
                                    <b>Element Selector</b>: To target a specific HTML element type, simply use its <b>tag
                                    name</b>. For
                                    instance, h2 targets all &lt;h2&gt; elements
                                </p>
                                <SyntaxHighlighter wrapLongLines={false} language="CSS" style={vs2015}>
                                    {'h2 {\n' +
                                    '     color: white;\n' +
                                    '}'}
                                </SyntaxHighlighter>
                                <p className={cl.courseTextWrap + ' ' + cl.mt}>
                                    <b>Class Selector</b>: If you want to style multiple elements with a <b>common trait</b>,
                                    add a
                                    class attribute to those elements (e.g., <b>&lt;p class="highlight"&gt;</b>) and
                                    use <b>.highlight</b> as your
                                    selector (don't forget the dot before class name)
                                </p>
                                <SyntaxHighlighter wrapLongLines={false} language="CSS" style={vs2015}>
                                    {'.white {\n' +
                                    '     color: #FFF;\n' +
                                    '}'}
                                </SyntaxHighlighter>
                                <p className={cl.courseTextWrap + ' ' + cl.mt}>
                                    <b>ID Selector</b>: When you want to style a <b>unique element</b>, give it a unique id
                                    attribute
                                    (e.g., <b>&lt;div id="uniqueID"&gt;</b>) and use <b>#uniqueID</b> as your selector
                                </p>
                                <SyntaxHighlighter wrapLongLines={false} language="CSS" style={vs2015}>
                                    {'#thirdBall {\n' +
                                    '     width: 30px;\n' +
                                    '}'}
                                </SyntaxHighlighter>
                                <p className={cl.courseTextWrap + ' ' + cl.mt}>
                                    <b>Combination Selectors</b>: You can combine selectors to target elements based on
                                    multiple criteria. For example, <b>"ul.navbar li.active"</b> targets a <b>list item</b> (
                                    &lt;li&gt;) with the <b>class active</b> inside an <b>unordered list</b> (
                                    &lt;    ul&gt;) with the <b>class navbar</b>
                                </p>
                                <SyntaxHighlighter wrapLongLines={false} language="CSS" style={vs2015}>
                                    {'ul.navbar li.active {\n' +
                                    '     width: 30px;\n' +
                                    '}'}
                                </SyntaxHighlighter>
                                <p className={cl.courseTextWrap}>
                                    * <b>Comma</b> in Selector: If you want to apply the same styles
                                    to <b>multiple</b> selectors, you
                                    can separate them with commas<br/>
                                    * <b>Space</b> in Selector: The space in a selector indicates that you are targeting a
                                    <b>descendant</b> element
                                </p>
                            </div>
                            <div className={cl.paragraphWrap}>
                                <div className={cl.courseTitleWrap}>
                                    <TitleNumbered title={'Priority'} number={4}/>
                                </div>
                                <p className={cl.courseTextWrap}>
                                    When there's a <b>conflict </b>between these rules, CSS uses a system called the <b>"cascading
                                    order" </b>
                                    to determine which style to apply
                                </p>
                                <SyntaxHighlighter wrapLongLines={false} language="htmlbars" style={vs2015}>
                                    {'<p class="human highlight" id="steve">\n' +
                                    '       Here could be any text\n' +
                                    '</p>'}
                                </SyntaxHighlighter>

                                <p className={cl.courseTextWrap}>
                                    <b>Specificity:</b> CSS assigns a specificity value to each selector. The more
                                    specific
                                    a selector
                                    is,
                                    the higher its priority. For example, an <b>ID</b> selector (#uniqueID) is <b>more
                                    specific </b>
                                    than a
                                    <b> class </b>
                                    selector (.highlight), and it takes precedence
                                </p>

                                <p className={cl.courseTextWrap}>
                                    <b>Importance:</b> Some styles may be marked as important using <b>!important</b>. These
                                    styles
                                    have the
                                    <b> highest priority</b> and override <b>any</b> conflicting styles that don't have the
                                    !important
                                    flag. But using the !important flag in CSS can lead to code <b>maintainability
                                    issues</b> and make
                                    it harder to <b>override</b> styles in the future so it's better to use it only in <b>case
                                    of need</b>
                                </p>
                                <SyntaxHighlighter wrapLongLines={false} language="CSS" style={vs2015}>
                                    {'h2 {\n' +
                                    '     color: white!important;\n' +
                                    '}'}
                                </SyntaxHighlighter>
                                <p className={cl.courseTextWrap}>
                                    <b>Source Order:</b> When selectors have the same specificity and importance, the
                                    rule
                                    that appears
                                    <b> last</b> in the <b>CSS file</b> takes precedence. In other words, the <b>order</b> in
                                    which
                                    styles are defined <b>matters</b>
                                </p>
                                <SyntaxHighlighter wrapLongLines={false} language="CSS" style={vs2015}>
                                    {'h2 {\n' +
                                    '     color: white;\n' +
                                    '}\n\n' +
                                    'h2, h1 {\n' +
                                    '     color: blue; /* This style is applied to h2 */\n' +
                                    '}'

                                    }
                                </SyntaxHighlighter>
                                <p className={cl.courseTextWrap}>
                                    <b>Style attribute in HTML</b>: It allows you to apply inline styles <b>directly</b> to
                                    individual
                                    HTML elements. Inline styles have the highest priority in the cascade,
                                    even <b>higher</b> than styles
                                    marked as <b>!important</b> in an external CSS file
                                </p>
                                <SyntaxHighlighter wrapLongLines={false} language="htmlbars" style={vs2015}>
                                    {'<p style="color: red;">This text is red</p>'}
                                </SyntaxHighlighter>
                                <p className={cl.courseTextWrap}>
                                    <b>*</b> But it's recommended to use a <b>separate CSS file</b> for most of your styling
                                    needs, especially
                                    for larger and more structured projects. This approach offers better organization,
                                    maintainability, and scalability
                                </p>
                            </div>
                            <div className={cl.paragraphWrap}>
                                <div className={cl.courseTitleWrap}>
                                    <TitleNumbered title={'Inheritance'} number={5}/>
                                </div>
                                <p className={cl.courseTextWrap}>
                                    In CSS, some properties are <b>inherited</b> by child elements from
                                    their <b>parent</b> elements. This
                                    means that when you apply a style to a parent element, such as a <b>&lt;div&gt;</b> or
                                    a <b>&lt;p&gt;</b>,
                                    some of
                                    those styles will <b>automatically apply</b> to the <b>child elements</b> within that
                                    parent, like text
                                    color or font properties
                                </p>
                                <p className={cl.courseTextWrap}>
                                    For example, if you set the <b>text color</b> to blue for a <b>&lt;div&gt;</b> that wraps
                                    around a
                                    paragraph <b>&lt;p&gt;</b>, the text inside the <b>&lt;p&gt;</b> will <b>also</b> be blue
                                    unless
                                    you <b>override </b>it with a
                                    different style. This inheritance feature simplifies styling and ensures <b>consistency </b>
                                    across your website because child elements inherit the styles of their parent by default
                                </p>
                                <p className={cl.courseTextWrap}>
                                    However, <b>not all</b> CSS properties are inherited. Properties
                                    like <b>background-image</b> or <b>border </b>
                                    are not inherited, so you need to explicitly <b>set</b> those styles for <b>each element</b>.
                                    Understanding which CSS properties are inherited and which are not is an important aspect of
                                    web design, and this knowledge becomes clearer <b>with practice</b>. So, keep practicing,
                                    and over
                                    time, you'll develop a strong grasp of how CSS inheritance works!
                                </p>
                            </div>
                            <div className={cl.paragraphWrap}>
                                <div className={cl.courseTitleWrap}>
                                    <TitleNumbered title={'First properties: Box model'} number={6}/>
                                </div>
                                <p className={cl.courseTextWrap}>
                                    The <b>Box Model</b> is a fundamental concept in CSS that helps define how elements are
                                    structured
                                    on a web page. Imagine each HTML element as a <b>box</b>, and this "box" has
                                    different <b>layers</b>:
                                </p>
                                <img src={boxModel} alt="boxModelPicture"/>
                                <p className={cl.courseTextWrap}>
                                    <b>Content:</b> This is the <b>inner part</b> of the box where your <b>actual content</b>,
                                    like text,
                                    images, or
                                    other media, resides. It's what you see as the core of the element
                                </p>
                                <p className={cl.courseTextWrap}>
                                    <b>Padding:</b> The padding is the <b>space</b> between the <b>content</b> and the <b>inner
                                    edge </b>of the box.
                                    It
                                    provides some breathing room for your content, creating space between the <b>content</b> and
                                    the
                                    <b> border</b>
                                </p>
                                <p className={cl.courseTextWrap}>
                                    <b>Border:</b> The border surrounds the padding and content. It's like a <b>frame</b> around
                                    the
                                    content,
                                    and you can set properties like color, style, and thickness for the border
                                </p>
                                <p className={cl.courseTextWrap}>
                                    <b>Margin:</b> The margin is the space between the border and the <b>neighboring
                                    elements</b>. It
                                    provides
                                    <b> separation</b> between elements on your page
                                </p>
                                <SyntaxHighlighter wrapLongLines={false} language="css" style={vs2015}>
                                    {'.ourBlock {\n' +
                                    '      margin: 5px;\n' +
                                    '      padding: 10px;\n' +
                                    '      border: 4px solid blue;\n' +
                                    '}'}
                                </SyntaxHighlighter>
                            </div>
                            <div className={cl.paragraphWrap}>
                                <div className={cl.courseTitleWrap}>
                                    <TitleNumbered title={'Display and position'} number={7}/>
                                </div>
                                <p className={cl.courseTextWrap}>
                                    CSS provides various <b>display</b> property values to determine <b>how elements are
                                    rendered</b>. Common values include:
                                </p>
                                <ol>
                                    <li>
                                        <p>
                                            <b className={cl.tag}> block</b> : Elements with this display value start on a <b>new
                                            line</b> and stretch to the <b>full
                                            width</b> of their container, stacking <b>on top of each other</b>
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <b className={cl.tag}>inline</b> : These elements flow within <b>the same
                                            line</b> as
                                            their
                                            adjacent content and only take up <b>as much width as necessary</b>. Examples
                                            include <b>&lt;span&gt;</b> and <b>&lt;a&gt;</b> elements (by default)
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <b className={cl.tag}> inline-block</b> : Similar to inline, but you can <b>set
                                            width </b>
                                            and <b>height</b> properties, making them suitable for combining text and
                                            block-level
                                            elements
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <b className={cl.tag}> flex</b> and <b className={cl.tag}> grid</b> : These are used
                                            for creating <b>flexible </b>
                                            and <b>grid-based</b> layouts, offering advanced control over element positioning
                                            and
                                            alignment
                                        </p>
                                    </li>
                                </ol>
                                <p className={cl.courseTextWrap}>
                                    The <b>position</b> property controls how an element is positioned within its containing
                                    element.
                                    Common values include:
                                </p>
                                <ol>
                                    <li>
                                        <p>
                                            <b className={cl.tag}> static</b> : This is the <b>default</b> positioning, where
                                            elements
                                            flow in the document's natural order
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <b className={cl.tag}>relative</b> : Elements are positioned relative to their
                                            <b> natural position</b> in the document flow. You can use properties like top,
                                            left, right,
                                            and bottom to offset them
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <b className={cl.tag}> absolute</b> : Elements are positioned relative to their
                                            <b> nearest positioned ancestor</b> (any ancestor with a position other than static)
                                            or the
                                            viewport itself. Absolute positioning takes elements <b>out of the normal flow</b>
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <b className={cl.tag}> fixed</b> : Elements are
                                            positioned relative to the viewport and <b>do not move</b> when the page is scrolled
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <b className={cl.tag}> sticky</b> : Elements
                                            behave like relative positioning until they reach a <b>specified scroll position</b>,
                                            after
                                            which they become fixed
                                        </p>
                                    </li>
                                </ol>
                                <p className={cl.courseTextWrap}> By using these <b>display</b> and <b>position</b> properties,
                                    web developers
                                    can precisely <b>control how
                                        elements appear</b> on a web page, enabling them to create various layouts and designs.
                                    <b> Understanding</b> these concepts is <b>crucial</b> for effective web design and layout
                                    control
                                </p>

                            </div>
                            <div className={cl.paragraphWrap}>
                                <div className={cl.courseTitleWrap}>
                                    <TitleNumbered title={'CSS Keyframes'} number={8}/>
                                </div>
                                <p className={cl.courseTextWrap}>
                                    <b>CSS keyframes</b> are a crucial aspect of web development that enable the creation of
                                    engaging
                                    and dynamic <b>animations</b> on web pages. They work by defining a <b>series</b> of
                                    intermediate <b>steps</b>
                                    between the <b>initial</b> and <b>final</b> states of an element, allowing you to control
                                    how that element
                                    changes over time. Think of keyframes as a <b>set of instructions</b> that guide the browser
                                    on how
                                    to smoothly transition from one style to another
                                </p>
                                <p className={cl.courseTextWrap}>
                                    To <b>create</b> a keyframe animation, you start by defining keyframes using
                                    the <b>@keyframes</b> rule.
                                    These keyframes represent specific <b>points in time</b> during the animation and define the
                                    styles
                                    at those moments. For example, you can create a simple <b>"changing color"</b> animation
                                    like this:
                                </p>

                                <SyntaxHighlighter wrapLongLines={false} language="css" style={vs2015}>
                                    {'@keyframes color-change {\n' +
                                    '  0% {\n' +
                                    '    background-color: red;\n' +
                                    '  }\n' +
                                    '  50% {\n' +
                                    '    background-color: blue;\n' +
                                    '  }\n' +
                                    '  100% {\n' +
                                    '    background-color: green;\n' +
                                    '  }\n' +
                                    '}\n' +
                                    '\n' +
                                    '.my-element {\n' +
                                    '  animation: color-change 2s linear infinite;\n' +
                                    '  width: 100px;\n' +
                                    '  height: 100px;\n' +
                                    '}\n'}
                                </SyntaxHighlighter>
                                <p className={cl.courseTextWrap}>
                                    In this example, we've defined a keyframe animation
                                    named <b>"color-change".</b> It <b>transitions </b>the
                                    <b> background color</b> of an element with the class <b>.my-element</b> from red to blue to
                                    green. The
                                    animation takes <b>2</b> seconds to complete, follows a <b>linear</b> timing
                                    function (constant speed),
                                    and repeats <b>infinitely</b>. You can apply this animation to <b>any</b> element with
                                    the <b>.my-element </b>
                                    class, and it will cycle through the specified colors in a continuous loop.
                                </p>
                                <p className={cl.courseTextWrap}>
                                    Feel free to <b>experiment</b> with this animation yourself and explore the full range of
                                    animation
                                    properties and options available <b>online</b>
                                </p>
                            </div>
                            <div className={cl.paragraphWrap}>
                                <div className={cl.courseTitleWrap}>
                                    <TitleNumbered title={'Viewport'} number={'*9'}/>
                                </div>
                                <p className={cl.courseTextWrap}>
                                    The <b>viewport</b> in CSS is a critical concept for web development as it determines how
                                    web
                                    content is displayed on <b>various devices</b> and <b>screen sizes</b>. Simply put, it's
                                    the <b>visible area </b>
                                    of a web page within a user's browser window. The viewport's size may vary depending on the
                                    user's device, whether it's a desktop monitor, a tablet, or a smartphone
                                </p>
                                <p className={cl.courseTextWrap}>
                                    To ensure that your web page looks great across different devices, CSS provides a <b>responsive
                                    design approach.</b> You can use CSS properties like width, height, and media queries to
                                    adapt
                                    your layout and styling to <b>fit the dimensions</b> of the viewport. For instance, you can
                                    set a
                                    responsive design that adjusts the font size or rearranges page elements when the viewport
                                    becomes narrower, ensuring a user-friendly experience on <b>both</b> large screens and
                                    mobile
                                    devices
                                </p>

                                <SyntaxHighlighter wrapLongLines={false} language="css" style={vs2015}>
                                    {'/* Example of a media query for screens narrower than 600px */\n' +
                                    '@media (max-width: 600px) {\n' +
                                    '  body {\n' +
                                    '    font-size: 16px; /* Adjust font size for smaller screens */\n' +
                                    '  }\n' +
                                    '}'}
                                </SyntaxHighlighter>
                                <p className={cl.courseTextWrap}>
                                    In this example, a <b>media query</b> is used to change the font size of the body element
                                    when the
                                    <b>viewport</b> width is less than or equal to <b>600 pixels</b>. This helps maintain
                                    readability and
                                    usability on smaller screens. Understanding and effectively using the viewport concept and
                                    responsive design techniques are essential for creating websites that look and function well
                                    across a wide range of devices and screen sizes
                                </p>

                            </div>
                        </div>

                        }

                    </div>
                </div>
                {user?.subscriptionStatus !== 'Paid' && <div className={cl.banner}><div className={cl.blur}></div><div className={cl.offerSmall}><OfferSmall/></div></div>}

            </div>
        );
    }
;

export default CssGuide;