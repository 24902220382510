import React from 'react';
import cl from './ProjectDescription.module.css'
import {semiBold} from "../../../../shared/styles/styles";

const ProjectDescription = ({data}) => {
    return (
        <div className={cl.wrap}>
            <p>
                {data[0]}
            </p>
            <hr className={cl.sepLine}/>
            <p style={{fontWeight:semiBold}}>
                {data[1]}
            </p>
            <hr className={cl.sepLine}/>
            <p>
                {data[2]}
            </p>
        </div>
    );
};

export default ProjectDescription;