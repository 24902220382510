import React from 'react';
import cl from './Projects.module.css'
import ProjectsRouter from "./router/ProjectsRouter";
import {useMediaQuery} from "../../shared/utils/hooks/useMediaQuery";
import {projectsRoutes} from "./constant/projectsData";
import ProjectMenuContainer from "./ui/ProjectMenuContainer/ProjectMenuContainer";

const Projects = () => {
    //TODO initial page without any project
    const isMobileVersion = useMediaQuery('(max-width: 992px)');

    return (
        <div className={cl.wrap}>
            {!isMobileVersion && <ProjectMenuContainer projectListDetails={projectsRoutes}/>}
            <div className={cl.petProjectRouterSkeleton}>
                <ProjectsRouter/>
            </div>
        </div>
    );
};

export default Projects;