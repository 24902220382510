import React from 'react';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom'
import {authRoutes, paidRoutes, publicRoutes} from "../constant/routes";
import {projectsRoutes} from "../../pages/Projects/constant/projectsData";
import {useSelector} from "react-redux";
import {AnimatePresence, motion} from "framer-motion";
import Projects from "../../pages/Projects/Projects";
import {useMediaQuery} from "../../shared/utils/hooks/useMediaQuery";
import ProjectMenu from "../../features/ProjectMenu/ProjectMenu";
import {useLinksTopAutoScroll} from "../../shared/utils/hooks/useLinksTopAutoScroll";
import ProjectMenuContainer from "../../pages/Projects/ui/ProjectMenuContainer/ProjectMenuContainer";

const AppRouter = () => {
    const isAuth = useSelector(state => state.isAuth.isAuth);
    const isPaid = useSelector(state => state.userSessionData.userSessionData?.userDetails?.subscriptionStatus) === 'Paid';

    const location = useLocation();
    const isMobileVersion = useMediaQuery('(max-width: 992px)');
    useLinksTopAutoScroll();

    return (
        <AnimatePresence mode='wait'>
            <Routes location={location} key={location.pathname}>
                {isAuth && authRoutes.map(route =>
                    <Route path={route.path}
                           element={
                               <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
                                   <route.component/>
                               </motion.div>
                           }
                           exact={route.exact}
                           key={route.path}/>
                )}

                {authRoutes.map(route =>
                    <Route path={route.path}
                           element={<motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
                               <Navigate to="/login" replace/>
                           </motion.div>
                           }
                           exact={route.exact}
                           key={route.path}/>
                )}

                {publicRoutes.map(route =>
                    <Route element={<motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
                        <route.component/>
                    </motion.div>}
                           path={route.path}
                           exact={route.exact}
                           key={route.path}/>
                )
                }


                {/* TODO Add route specifically to /pet-projects page */}
                {isMobileVersion &&
                <Route element={<ProjectMenuContainer projectListDetails={projectsRoutes}/>}
                       path={'pet-projects'}
                       exact={true}
                       key={'pet-projects'}/>}
                {isMobileVersion &&
                <Route element={<Projects/>}
                       path={'pet-projects/*'}
                       exact={true}
                       key={'pet-projects'}/>}
                {!isMobileVersion &&
                <Route element={<Projects/>}
                       path={'pet-projects/*'}
                       exact={true}
                       key={'pet-projects'}/>
                }

                {isPaid && paidRoutes.map(route =>
                    <Route path={route.path}
                           element={<motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
                               <route.component/>
                           </motion.div>
                           }
                           exact={route.exact}
                           key={route.path}/>
                )}
                {paidRoutes.map(route =>
                    <Route path={route.path}
                           element={<motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
                               <Navigate to="/login" replace/>
                           </motion.div>
                           }
                           exact={route.exact}
                           key={route.path}/>
                )}
                <Route path="*" element={<Navigate to="/not-found" replace/>}/>
            </Routes>
        </AnimatePresence>
    );
};

export default AppRouter;