import React, {useState} from 'react';
import cl from './Contacts.module.css'
import Button_lilac_outline from "../../shared/UI/Button_lilac_outline/Button_lilac_outline";
import Sticks from "../../shared/UI/Sticks/Sticks";
import {data} from "./constant/faqText";
import CustomFaq from "./ui/faq/CustomFaq";
import {useSelector} from "react-redux";
import {emailApi} from "../../shared/api/emailApi";
import FeedbackIsSentPopup from "../../widgets/popups/FeedbackIsSentPopup/FeedbackIsSentPopup";

const Contacts = () => {
    const userDetails = useSelector(state => state.userSessionData.userSessionData.userDetails)

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState(userDetails ? userDetails.email : '')
    const [message, setMessage] = useState('')

    const [showFeedbackIsSentPopup, setShowFeedbackIsSentPopup] = useState(false)

    async function handleSendForm(e) {
        e.preventDefault();
        if (firstName && lastName && email && message) {
            await emailApi.sendEmailToWripet(email, firstName, lastName, message)
            setShowFeedbackIsSentPopup(true)
        }
    }

    return (
        <div className={cl.wrap}>
            {showFeedbackIsSentPopup &&
                <FeedbackIsSentPopup email={email} setPopupIsVisible={setShowFeedbackIsSentPopup}/>}

            <div className={cl.contactSection}>
                <div className={cl.titleWrap}>
                    <h4 className={cl.subtitle}>Contact us</h4>
                    <h1 className={cl.mainTitle}>Let's talk</h1>
                </div>
                <form>
                    <h4 className={cl.comment}>Drop us a line through the form below and we'll get back to you soon</h4>
                    <Sticks styles={{
                        width: '4px', leftHeight: '32vh', rightHeight: '40vh', leftStickTop: '0', rightStickTop: '80px',
                        distance: '-30px', wrapTop: '20%',
                        wrapLeft: '110%', background: 'white'
                    }}/>
                    <Sticks styles={{
                        width: '4px', leftHeight: '20vh', rightHeight: '20vh', leftStickTop: '0', rightStickTop: '80px',
                        distance: '30px', wrapTop: '-10%',
                        wrapLeft: '-10%', background: 'white'
                    }}/>
                    <div className={cl.inputsWrap}>
                        <div className={cl.fullName}>
                            <input type="text" placeholder={'First name*'} value={firstName}
                                   onChange={(e) => setFirstName(e.target.value)}
                            />
                            <input type="text" placeholder={'Last name*'} value={lastName}
                                   onChange={(e) => setLastName(e.target.value)}
                            />
                        </div>
                        <input type="email" placeholder={'Email*'} value={email}
                               onChange={(e) => (!userDetails) && setEmail(e.target.value)}
                               style={userDetails ? { pointerEvents: 'none'} : {}}/>
                        <textarea placeholder={'Message*'} value={message}
                                  onChange={(e) => setMessage(e.target.value)}/>
                    </div>

                    <Button_lilac_outline className={cl.button}
                                          onClick={(e) => handleSendForm(e)}>Send</Button_lilac_outline>
                </form>
            </div>
            <div className={cl.faqSection}>
                <div className={cl.faqWrap}>
                    <CustomFaq data={data}/>
                </div>
                <div className={cl.titleWrap +' ' +cl.faqTileWrap}>
                    <h4 className={cl.subtitle}>Most asked questions</h4>
                    <h1 className={cl.mainTitle}>FAQ</h1>
                </div>
            </div>

        </div>
    );
};

export default Contacts;