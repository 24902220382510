import React from 'react';
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import {projectsRoutes as projectRoutes} from "../constant/projectsData";
import {motion} from "framer-motion";
import Loader from "../../../shared/UI/Loader/Loader";
import {useSelector} from "react-redux";
import {useMediaQuery} from "../../../shared/utils/hooks/useMediaQuery";

const ProjectsRouter = () => {
    const location = useLocation();
    const isAuth = useSelector(state => state.isAuth.isAuth)
    const user = useSelector(state => state.userSessionData.userSessionData.userDetails);
    const isMobileVersion = useMediaQuery('(max-width: 992px)');

    const freeProjectRoutes = projectRoutes.filter((i)=>i.accessLevel==='free')
    const paidProjectRoutes = projectRoutes.filter((i)=>i.accessLevel==='paid')
    return (
        <Routes location={location} key={location.pathname}>
            {!isMobileVersion &&
            <Route element={<motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
                <Navigate to="/pet-projects/hamburger-menu" replace/>
            </motion.div>}
                   path="/"
                   exact="true"
                   loader={<Loader/>}
            />}

            {freeProjectRoutes.map(route =>
                <Route element={<motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
                    <route.component/>
                </motion.div>}
                       path={route.path}
                       exact={route.exact}
                       key={route.path}
                       loader={<Loader/>}
                />
            )}

            {user?.subscriptionStatus === 'Paid' && paidProjectRoutes.map(route =>
                <Route element={<motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
                    <route.component/>
                </motion.div>}
                       path={route.path}
                       exact={route.exact}
                       key={route.path}
                       loader={<Loader/>}
                />
            )}
            {isAuth && paidProjectRoutes.map(route =>
                <Route element={
                    <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
                        <Navigate to="/offer"/>
                    </motion.div>}
                       path={route.path}
                       exact={route.exact}
                       key={route.path}
                       loader={<Loader/>}
                />
            )}

            {paidProjectRoutes.map(route =>
                <Route element={
                    <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
                        <Navigate to="/login"/>
                    </motion.div>}
                       path={route.path}
                       exact={route.exact}
                       key={route.path}
                       loader={<Loader/>}
                />
            )}
        </Routes>
    );
};

export default ProjectsRouter;