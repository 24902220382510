import React from 'react';
import cl from './Loader.module.css'
import loaderImg from "../../assets/transparentWripetWithWhiteBackground.png";
import {AnimatePresence, motion} from "framer-motion";

const Loader = () => {
    return (
        <div className={cl.wrap} key={Math.random()}>
            <div className={cl.text}>
                <img className={cl.img} src={loaderImg} alt="Loader"/>
            </div>
        </div>
    );
};

export default Loader;