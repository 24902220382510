import React from 'react';
import TitleWithComment from "../../../../../shared/UI/TitleWithComment/TitleWithComment";
import ProjectDescription from "../../../../../widgets/Project/ui/ProjectDescription/ProjectDescription";
import cl from './TryProjectTab.module.css'

const TryProjectTab = ({data}) => {

    return (
        <div className={cl.wrap}>
            <div className={cl.titleWithCommentWrap}>
                <TitleWithComment
                    data={{title: data.title, comment: data.comment, leftHeight: data.leftHeight}}/>
            </div>
            <div className={cl.content}>
                <div className={cl.appDemoWrap}>
                    {data.appDemo}
                </div>
                <ProjectDescription data={data.descriptionArray}/>
            </div>
        </div>
    );
};

export default TryProjectTab;