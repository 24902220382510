import React, {useEffect, useState} from 'react';
import cl from './Timer.module.css'

const Timer = ({setStart, start, secondsNumber}) => {
    const [seconds, setSeconds] = useState(secondsNumber);
    let interval;

    useEffect(() => {
        if (start) {
            setSeconds(secondsNumber)
            interval = setInterval(() => setSeconds((seconds) => (seconds - 1)), 1000);
        }
        return () => clearInterval(interval);

    }, [start])

    useEffect(() => {
        if (seconds === 0) {
            clearInterval(interval)
            setStart(false)
        }

    }, [seconds]);

    return (
        <span className={cl.wrap}>
            {seconds}
        </span>
    );
};

export default Timer;