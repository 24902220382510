import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useLinksTopAutoScroll = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo({left:0, top:0, behavior:"smooth"});
    }, [pathname]);

}